import React, { createContext, useState, useContext } from 'react';

const AlbumContext = createContext();

export const AlbumProvider = ({ children }) => {
  const [albumDetails, setAlbumDetails] = useState({});
  const [artworkStatus, setArtworkStatus] = useState(null);
  const [audioStatus, setAudioStatus] = useState(null);

  return (
    <AlbumContext.Provider value={{ albumDetails, setAlbumDetails, artworkStatus, setArtworkStatus,audioStatus, setAudioStatus }}>
      {children}
    </AlbumContext.Provider>
  );
};

export const useAlbum = () => useContext(AlbumContext);
