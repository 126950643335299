// VideoPopup.js
import React from 'react';
import Video from '../../assets/images/video.svg'


const VideoPopup = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen || !videoSrc) return null;

  return (
    <>
      <div className="popup-fixed"></div>
      <div className="popup-container popup-container-fixed" data-aos="fade-down" data-aos-easing="linear">
        <div className="video-popup">
          <div className="container" data-aos="fade-down" data-aos-easing="linear">
            <div className="row">
              <div className="col-md-4 m-auto shadow" style={{ backgroundColor: '#fff' , borderRadius: "0 0 15px 15px"}}>
                <div className="relative">
                  <span className="close-popup" onClick={onClose}></span>
                  <div className="col-md-12">
                  <div className="terms-space help-space">
                  <div className="video-container">
                    <video controls autoPlay style={{ height: '500px', width: '360px'  }}>
                      <source src={videoSrc} type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPopup;
