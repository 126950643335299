import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import UploadAudio from '../../components/uploadAudio/uploadAudio';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import Header from '../header/header';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import '../../assets/css/global.css';
import Upload from '../../assets/images/icon-upload-black.svg'
import ReCAPTCHA from 'react-google-recaptcha';
import createAlbumArtwork from '../../apis/createAlbumArtwork/createAlbumArtwork';
import Cross from '../../assets/images/icon-cross.svg';
import { useAlbum } from '../../components/privateRoute/albumContext';
import GetRepersentativeList from '../../apis/getRepersentativeList/getRepersentativeList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ArtWorkForm = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [images, setImages] = useState([]);
    const [error, setError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [stateError, setStateError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const { state } = useLocation();
    const { albumDetails } = useAlbum();
    const title = sessionStorage.getItem('title');
    const [representatives, setRepresentatives] = useState([]);
    const selectCountry = (val) => {
        setCountry(val);
        setRegion('');
        setFormData(prevFormData => ({
            ...prevFormData,
            Country: val,
            State: ''
        }));
        setCountryError('');
    };

    
    const selectRegion = (val) => {
        setRegion(val);
        setFormData(prevFormData => ({
            ...prevFormData,
            State: val
        }));
        setStateError('');
    };


    useEffect(() => {
        AOS.init();
        document.body.classList.remove('home-bg');
        
        if (!id) {
            navigate('/');
        }
    }, [id, navigate]);

   
    

    const [activeTab, setActiveTab] = useState('artwork');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        HowFindUs: '',
        CardPrintingRep: '',
        Representative: '',
        AdditionalDetails: '',
        FirstName: '',
        LastName: '',
        Company: '',
        Email: '',
        Phone: '',
        StreetAddress: '',
        AptSuite: '',
        City: '',
        Country: '',
        State: '',
        ZipCode: '',
        Comment: ''
    });

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab == 'audio') {
            setPopupOpen(true);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';

        if (name === 'Phone') {
            if (!value.trim()) {
                errorMessage = 'The Phone field is required.';
            } else if (!/^\d{10}$/.test(value)) {
                errorMessage = 'The Phone is invalid';
            }
        } else if (name === 'Email') {
            if (!value.trim()) {
                errorMessage = 'Email Address is required';
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                errorMessage = 'Email Address is invalid';
            }
        } else if (name === 'ZipCode') {
            if (!value.trim()) {
            errorMessage = 'The Zip field is required.';
           } else if (!/^\d{1,10}$/.test(value)) {
            errorMessage = 'The Zip Code is invalid';
           }
        }
       
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });
    };

    const openAudioPopup = () => {
        setPopupOpen(true);
        console.log(isPopupOpen);
    };


    const closeAudioPopup = () => {
        setPopupOpen(false);
        navigate('/audio');
    };

    useEffect(() => {
        const authenticationToken = sessionStorage.getItem('token');
    
        const fetchRepresentatives = async () => {
            try {
                const response = await GetRepersentativeList(authenticationToken);
    
                if (response.result === 1) {
                    const fetchedRepresentatives = response.resultValue;
                    setRepresentatives(fetchedRepresentatives);
    
                    const representativeName = sessionStorage.getItem('representative'); // Fetch representative name
                    const selectedRepresentative = fetchedRepresentatives.find(rep => rep.userName === representativeName);
    
                    if (selectedRepresentative) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            Representative: selectedRepresentative.userId, // Set Representative ID
                        }));
                    }
                } else {
                    console.error('Failed to fetch representatives:', response.errorValue);
                }
            } catch (error) {
                console.error('Error fetching representatives:', error);
            }
        };
    
        fetchRepresentatives();
    }, []);
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate(formData);
        console.log(validationErrors);
         
        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true);
            const submitData = new FormData();
            submitData.append('LastName', formData.LastName);
            submitData.append('Zip', formData.ZipCode);
            submitData.append('HowFindUs', formData.HowFindUs);
            submitData.append('AlbumId', id);
            submitData.append('City', formData.City);
            submitData.append('Phone', formData.Phone);
            submitData.append('State', formData.State);
            submitData.append('StreetAddress', formData.StreetAddress);
            //submitData.append('Status', 1);
            submitData.append('AptSuite', formData.AptSuite);
            //submitData.append('CreatedDate', new Date().toISOString());
            submitData.append('Country', formData.Country);
            //submitData.append('ModifiedDate', new Date().toISOString());
            submitData.append('Id', 0);
            submitData.append('FirstName', formData.FirstName);
            submitData.append('Email', formData.Email);
            submitData.append('Company', formData.Company);
            submitData.append('RepresentativeId', formData.Representative);
            submitData.append('Comment', formData.Comment);

            images.forEach((image, index) => {
                submitData.append('FormFiles', image);
            });
               console.log(submitData);
            try {
                const authenticationToken = sessionStorage.getItem('token');
                const response = await createAlbumArtwork(authenticationToken, submitData)
                if (response.result === 1 && response.resultValue) {
                    toast.success('Art Work successfully Created!');
                    setTimeout(() => {
                        navigate('/artwork/'+id);
                      }, 2000);
                } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 103) {
                    console.error('Invalid authentication token:', response.errorValue.errorText);
                    sessionStorage.clear();
                    navigate('/');

                } else {
                    console.error('API call returned an error:', response);

                }

            } catch (error) {
                console.error('Error:', error);

            } finally {
                setIsLoading(false);
            }
        } else {
            setErrors(validationErrors);
            if (!captchaValue) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    captcha: 'Please verify you are a human'
                }));
            }
        }
    };
    const handleDeleteFile = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        if (value) {
            setErrors((prevErrors) => {
                const { captcha, ...rest } = prevErrors;
                return rest;
            });
        }
    };
    const validateFileType = (file) => {
        const allowedTypes = [
            'image/jpeg', 'image/png', 'image/gif', 'application/postscript', 'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.oasis.opendocument.text',
            'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv', 'text/plain', 'font/otf', 'font/ttf', 'application/x-7z-compressed', 'application/x-font-pfb',
            'application/x-font-pfm', 'application/x-rar-compressed', 'application/zip', 'image/svg+xml', 'image/avif'
        ];
        if (!allowedTypes.includes(file.type)) {
            setError('Unsupported file type');
            return false;
        }
        return true;
    };

    const validateFileSize = (file) => {
        const maxSize = 100 * 1024 * 1024;
        if (file.size > maxSize) {
            setError('File size exceeds 100MB limit');
            return false;
        }
        return true;
    };
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        let validFiles = [];
        files.forEach(file => {
            if (validateFileType(file) && validateFileSize(file)) {
                validFiles.push(file);
            }
        });
        setImages([...images, ...validFiles]);
        if (validFiles.length > 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                image: ''
            }));
        }
    };


    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        let validFiles = [];
        files.forEach(file => {
            if (validateFileType(file) && validateFileSize(file)) {
                validFiles.push(file);
            }
        });
        setImages([...images, ...validFiles]);
        if (validFiles.length > 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                image: ''
            }));
        }
    };


    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const validate = (data) => {
        let errors = {};

        
        if (!data.HowFindUs) {
            errors.HowFindUs = 'The HowFindUs field is required.';
        }
        if (!data.Representative) {
            errors.Representative = 'The Representative field is required.';
        }
       
       
        if (!data.FirstName.trim()) {
            errors.FirstName = 'The First Name field is required.';
        }
        if (!data.LastName.trim()) {
            errors.LastName = 'The Last Name field is required.';
        }
        if (!data.Email.trim()) {
            errors.Email = 'The Email field is required.';
        } else if (!/\S+@\S+\.\S+/.test(data.Email)) {
            errors.Email = 'The Email Address is invalid';
        }
        if (!data.Phone.trim()) {
            errors.Phone = 'The Phone field is required.';
        } else if (!/^\d{10}$/.test(data.Phone)) {
            errors.Phone = 'The Phone is invalid';
        }
        if (!data.StreetAddress.trim()) {
            errors.StreetAddress = 'The Street Address field is required.';
        }
        if (!data.City.trim()) {
            errors.City = 'The City field is required.';
        }

        if (!country) {
            setCountryError('The Country field is required.');
        }
        if (!region) {
            setStateError('The State field is required.');
        }

        if (!data.ZipCode.trim()) {
            errors.ZipCode = 'The Zip field is required.';
        } else if (!/^\d{1,10}$/.test(data.ZipCode)) {
            errors.ZipCode = 'The Zip Code is invalid';
        }
        if (images.length === 0) {
            errors.image = 'At least one FormFiles is required';
        }
        if (!formData.Comment.trim()) {
            errors.Comment = 'The Comment field is required.';
        }

        return errors;
    };

    return (
        <>
            {isLoading && (
                <div className="loader-container">
                    <div className="loader"></div>

                </div>
            )}
            <Header />
            <div className="space"></div>
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 left-space">

                            <div className="alink">

                                <Link to={`/albums/`}>
                                    Albums
                                </Link>
                                <span  style={{ paddingLeft: "0.7rem" }} className='albumNameLink'>
                                        {title}
                                    </span>
                                
                                
                            </div>
                            <ul className="tab-Artwork">
                                <li className={activeTab === 'artwork' ? 'first active' : 'first'}>
                                    <a href="#" onClick={() => handleTabClick('artwork')}>Artwork</a>
                                </li>

                            </ul>
                        </div>

                        {/* Conditional rendering based on activeTab */}
                        {activeTab === 'artwork' && (
                            <>
                                <form className="row" onSubmit={handleSubmit}>
                                    <div className="col-md-6 bg-white height">
                                        <div className="Artwork-space">

                                            <h2>A little info about your project:</h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>How did you find us ?</label>
                                                        <select
                                                            className="input select"
                                                            value={formData.HowFindUs}
                                                            onChange={handleInputChange}
                                                            name="HowFindUs"
                                                        >
                                                            <option value="">Select how you found us</option>
                                                            <option value="google">Google</option>
                                                            <option value="linked">LinkedIn</option>
                                                            <option value="friends">Friends</option>
                                                        </select>
                                                        {errors.HowFindUs && <p className="error">{errors.HowFindUs}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label> Representative</label>
                                                        <select
    className="input"
    value={formData.Representative}
    onChange={handleInputChange}
    name="Representative"
>
    <option value="">Select Representative</option>
    {representatives.map(rep => (
        <option key={rep.userId} value={rep.userId}>
            {rep.userName}
        </option>
    ))}
</select>
{errors.Representative && (
    <p className="error">{errors.Representative}</p>
)}

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Card Printing Representative</label>
                                                        <p>Have you been in contact with a rep concerning your files/order?</p>
                                                    </div>
                                                </div>
                                               
                                                <div className="col-md-12">
                                                    <label>Artwork/Data files</label>
                                                    <p>Before uploading your files, please check:</p>
                                                    <ul>
                                                        <li>Outline all fonts, or include your fonts with your upload.</li>
                                                        <li>Include an additional .125" on all four sides for bleed.</li>
                                                        <li>Rasterized files must be a minimum of 300 dpi</li>
                                                        <li>Borders should be avoided in your designs</li>
                                                    </ul>
                                                    <p><label>Read or download our print guidelines</label></p>
                                                    <ul>
                                                        <li>Supported files: jpg, jpeg, gif, png, ai, psd, pdf, doc, docx, ppt, pptx, odt, xls, xlsx, csv, txt, otf, ttf, 7z, pfb, pfm, rar, zip, svg, avif and eps. 100 MB file max</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-12 align-center">
                                                    <div className="album-add">
                                                        <div className="drag"
                                                            onDrop={handleDrop}
                                                            onDragOver={handleDragOver}>
                                                            <p>
                                                                <img
                                                                    src={Upload}
                                                                    width="45"
                                                                    height="38"
                                                                    alt="upload"
                                                                />
                                                            </p>
                                                            {images.length > 0 ? (
                                                                images.map((image, index) => (
                                                                    <div key={index}>
                                                                        <p className='dropped-file'>Uploaded file: {image.name}
                                                                            <img
                                                                                src={Cross}
                                                                                alt="Delete"
                                                                                className="icon-delete"
                                                                                width={20}
                                                                                height={20}
                                                                                onClick={() => handleDeleteFile(index)}
                                                                            />
                                                                        </p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p>Drag & drop your image here</p>
                                                            )}
                                                            <p>or</p>
                                                            <label htmlFor="Upload" className="custom-file-upload">Browse files to upload</label>
                                                            <input
                                                                type="file"
                                                                id="Upload"
                                                                accept=".jpg,.jpeg,.png,.gif,.ai,.psd,.pdf,.doc,.docx,.ppt,.pptx,.odt,.xls,.xlsx,.csv,.txt,.otf,.ttf,.7z,.pfb,.pfm,.rar,.zip,.svg,.avif,.eps"
                                                                onChange={handleImageChange}
                                                                onDrop={handleDrop}
                                                                onDragOver={handleDragOver}
                                                                multiple
                                                            />
                                                        </div>

                                                        {errors.image && <p className="error">{errors.image}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="margin-bottom">
                                                        <textarea
                                                            className="input textarea"
                                                            placeholder="If you have additional details, please add them here."
                                                            cols="30"
                                                            rows="5"
                                                            name="Comment"
                                                            value={formData.Comment}
                                                            onChange={handleInputChange}
                                                        ></textarea>
                                                        {errors.Comment && <p className="error">{errors.Comment}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-6 height">
                                        <div className="Artwork-space">

                                            <h2>Where we can contact you?</h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>First Name</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="FirstName"
                                                            value={formData.FirstName}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.FirstName && <p className="error">{errors.FirstName}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Last Name</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="LastName"
                                                            value={formData.LastName}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.LastName && <p className="error">{errors.LastName}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Company Name</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="Company"
                                                            value={formData.Company}
                                                            onChange={handleInputChange}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>E-mail Address</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="Email"
                                                            value={formData.Email}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.Email && <p className="error">{errors.Email}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Phone</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="Phone"
                                                            value={formData.Phone}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.Phone && <p className="error">{errors.Phone}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Street Address</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="StreetAddress"
                                                            value={formData.StreetAddress}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.StreetAddress && <p className="error">{errors.StreetAddress}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Apt / Suite #</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="AptSuite"
                                                            value={formData.AptSuite}
                                                            onChange={handleInputChange}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>City</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="City"
                                                            value={formData.City}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.City && <p className="error">{errors.City}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Country</label>
                                                        <CountryDropdown className="input select" name="Country" value={country} onChange={selectCountry} />
                                                        {countryError && <p className="error">{countryError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>State</label>
                                                        <RegionDropdown className="input select" name="State" country={country} value={region} onChange={selectRegion} />
                                                        {stateError && <p className="error">{stateError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <label>Zip/Postal Code</label>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            name="ZipCode"
                                                            value={formData.ZipCode}
                                                            onChange={handleInputChange}
                                                        />
                                                       
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="margin-bottom">
                                                        <ReCAPTCHA
                                                            sitekey="6Leg8_0pAAAAANdpGnA54ZrUANOh485-o6S7j1Ng"
                                                            onChange={handleCaptchaChange}
                                                            className="captha"
                                                        />
                                                        {errors.captcha && <p className="error">{errors.captcha}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="margin-bottom">
                                                        <p>* Please allow 24 hours to get back to you.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="margin-bottom">
                                                        <input type="submit" value="Upload your files" className="btn-submit" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </form>
                            </>
                        )}

                        <div className="col-md-12">

                            {isPopupOpen && <UploadAudio closeAudioPopup={closeAudioPopup} />}


                        </div>


                    </div>
                </div>
            </section>
       <ToastContainer />

        </>
    );
};

export default ArtWorkForm;
