import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const SubmitAlbumTrack = async (authenticationToken, albumId) => {
    try {
        const headers = {
            'Authorization': authenticationToken,
        };

        const params = {
            AlbumId: albumId,
        };

        const response = await instance.post('SubmitAlbumTrack', null, {
            params: params,
            headers: headers,
        });

        console.log('Response Data:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error submitting album track:', error);
        throw error;
    }
};

export default SubmitAlbumTrack;
