import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const CreateIPRAgreement = async (authenticationToken, formData) => {
    try {
        const headers = {
            'Authorization': authenticationToken,
            'Content-Type': 'application/json',
        };

        const response = await instance.post('CreateIPRAgreement', formData, {
            headers: headers,
        });

        return response.data;
    } catch (error) {
        console.error('Error sending response to agreement:', error);
        throw error;
    }
};

export default CreateIPRAgreement;
