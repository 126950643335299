import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, 
});

const CreateAlbum = async (authenticationToken, formData) => {
  try {
    const headers = {
      'Authorization': authenticationToken,
      'Content-Type': 'multipart/form-data',
    };

   const response = await instance.post('CreateAlbum', formData, { headers });
    console.log('Response Data:', response.data); 
    return response.data;
  } catch (error) {
    console.error('Error sending response to agreement:', error);
    throw error;
  }
};

export default CreateAlbum;
