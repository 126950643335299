import React, { useState, useEffect } from 'react';
import Header from '../header/header';
import Table from '../../components/table/table';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../../assets/css/global.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import UploadAlbum from '../../components/uploadAlbum/uploadAlbum'
import TitleIcon from '../../assets/images/icon-album.svg'
import ArrowCheck from '../../assets/images/icon-checkmark.svg'
import View from '../../assets/images/icon-view.svg'
import Waiting from '../../assets/images/icon-waiting.svg'
import GetAlbumList from '../../apis/getAlbumList/getAlbumList';
import UploadIcon from '../../assets/images/icon-upload.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useAlbum } from '../../components/privateRoute/albumContext';
import Help from '../help/help';
import IPRPopupForm from '../iprForm/iprForm';
import Footer from '../footer/footer';
import Cross from '../../assets/images/icon-cross.svg'
import BandDetailsPopup from './bandDetailsPopup';
import Filter from '../../assets/images/Filter.svg'
import CommentIcon from '../../assets/images/comment.svg'
import Edit from '../../assets/images/edit.svg'
import AlbumComment from './albumComment';
import GenerateProof from './generateProof';
import GenProof from '../../assets/images/generate_proof.svg'
import ViewProof from '../../assets/images/view_proof.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AlbumList = () => {
  const { setAlbumDetails, setArtworkStatus, setAudioStatus } = useAlbum();

  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [albumsPerPage] = useState(2);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortState, setSortState] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isAlbumPopupOpen, setAlbumPopupOpen] = useState(false);
  const [isIPRPopupOpen, setIPRPopupOpen] = useState(false);
  const [helpPopupOpen, setHelpPopupOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [albumValue, setAlbumValue] = useState('');
  const [isBandPopupOpen, setBandPopupOpen] = useState(false);
  const [selectedBand, setSelectedBand] = useState(null);
  const [selectedProof, setselectedProof] = useState(null);
  const [isFilterDivOpen, setFilterDivOpen] = useState(false);
  const [selectedArtworkStatus, setSelectedArtworkStatus] = useState(-1);
  const [selectedAudioStatus, setSelectedAudioStatus] = useState(-1);
  const [selectedPrintProofStatus, setSelectedPrintProofStatus] = useState(-1);
  const [selectedIprAgreement, setSelectedIprAgreement] = useState(-1);
  const [isProofPopupOpen, setProofPopupOpen] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedArtworkId, setSelectedArtworkId] = useState(null);
  const [repersentiveComment, setRepersentiveComment] = useState('');
  const [repersentiveStatus, setRepersentiveStatus] = useState('');
  const [commentPopupOpen, setCommentPopupOpen] = useState(false);
  const [commentHistory, setCommentHistory] = useState([]);
  const [editingAlbum, setEditingAlbum] = useState(null);
  const closeBandPopup = () => {
    setBandPopupOpen(false);
    setSelectedBand(null);
  };

  const openAlbumPopup = (album = null) => {
    setEditingAlbum(album); 
    setAlbumPopupOpen(true);
  };



  useEffect(() => {
    AOS.init();
    document.body.classList.remove('home-bg');
    const storedUserType = sessionStorage.getItem('userType');
    setUserType(storedUserType);
    fetchAlbums();
  }, [pageSize]);

  const fetchAlbums = async () => {
    setLoading(true);
    const authenticationToken = sessionStorage.getItem('token');
    try {
      const response = await GetAlbumList(authenticationToken);

      response.sort((a, b) => b.id - a.id);

      setAlbums(response);
      setFilteredData(response);
      const pages = Math.ceil(response.length / pageSize);
      setTotalPages(pages);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeAlbumPopup = () => {
    setAlbumPopupOpen(false);
    fetchAlbums();
  };

  const closeHelpPopup = () => {
    setHelpPopupOpen(false);
    fetchAlbums();
  };

  function handleSort(column) {
    const sortedData = [...filteredData];
    const currentSort = sortState[column] || "asc";
    sortedData.sort((a, b) => {
      if (currentSort === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    const newSortState = {
      ...sortState,
      [column]: currentSort === "asc" ? "desc" : "asc"
    };
    setFilteredData(sortedData);
    setSortState(newSortState);
  }

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = albums.filter((item) => (
      item.title && item.title.toLowerCase().includes(query.toLowerCase())
    ));
    const pages = Math.ceil(filteredData.length / pageSize);
    setTotalPages(pages);
    setFilteredData(filteredData);
  };

  const handleFilterByArtworkStatus = (selectedStatus) => {
    if (selectedStatus === -1) {
      setFilteredData(albums);
    } else {
      const filteredData = albums.filter((item) => (
        item.artworkStatus === selectedStatus
      ));
      setFilteredData(filteredData);
    }
    const pages = Math.ceil(filteredData.length / pageSize);
    setTotalPages(pages);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleIRPPopup = (id, title) => {
    setAlbumValue(id);
    setTitle(title);
    setIPRPopupOpen(true);
  }

  const openHelpPopup = () => {
    setHelpPopupOpen(true);
  }
  const handleClick = (id, title, status, audioStatus, representative, action) => {
    sessionStorage.setItem('title', title);
    sessionStorage.setItem('status', status);
    sessionStorage.setItem('audioStatus', audioStatus);
    sessionStorage.setItem('representative', representative);
    setAlbumDetails({ id, title, status });
    setArtworkStatus(status);
    setAudioStatus(audioStatus);
    switch (action) {
      case 'upload':
        navigate(`/form-for-artwork/${id}`);
        break;
      case 'audio':
        navigate(`/audio/${id}`);
        break;
      case 'artwork':
        navigate(`/artwork/${id}`);
        break;
      default:
        break;
    }
  };

  const handleBandClick = (band, title, description, representative, albumPic, createdByName, createdByEmail) => {
    setSelectedBand({
      band,
      title,
      description,
      representative,
      albumPic,
      createdByName,
      createdByEmail,
    });
    setBandPopupOpen(true);
  };

  const handleProofClick = (albumDetails, title, artworkProofURL, albumArtworkProofId) => {
    sessionStorage.setItem('title', title);
    if (userType === '2') {

      setSelectedAlbum(albumDetails);
      setSelectedArtworkId(albumDetails);
      setProofPopupOpen(true);

    } else {
      navigate(`/proof/${albumDetails}`, {
        state: {
          albumDetails,
          title,
          artworkProofURL,
          albumArtworkProofId
        },
      });
    }
  };

  const handleProofPage = (albumDetails, title, artworkProofURL, albumArtworkProofId, proofComment, proofStatus) => {
    sessionStorage.setItem('title', title);
    navigate(`/proof/${albumDetails}`, {
      state: {
        albumDetails,
        title,
        artworkProofURL,
        albumArtworkProofId,
        proofComment,
        proofStatus
      },
    });

  };

  const closeProofPopup = () => {
    setProofPopupOpen(false);
    setSelectedAlbum(null);
    fetchAlbums();
  };

  const openCommentPopup = (albumId, status, comment, albumArtworkProofId, history) => {
    setSelectedArtworkId(albumId);
    setRepersentiveComment(comment);
    setRepersentiveStatus(status);
    setCommentHistory(history);
    setCommentPopupOpen(true);
  };

  const closeCommentPopup = () => {
    setCommentPopupOpen(false);
    fetchAlbums();
  };

  useEffect(() => {
    if (selectedBand) {
      console.log(selectedBand);
    }
  }, [selectedBand]);

  const handleFilter = () => {
    let filtered = albums;

    if (selectedArtworkStatus !== -1) {
      filtered = filtered.filter(album => album.artworkStatus === selectedArtworkStatus);
    }

    if (selectedAudioStatus !== -1) {
      filtered = filtered.filter(album => album.audioFilesStatus === selectedAudioStatus);
    }

    if (selectedPrintProofStatus !== -1) {
      filtered = filtered.filter(album => album.printProofStatus === selectedPrintProofStatus);
    }

    if (selectedIprAgreement !== -1) {
      filtered = filtered.filter(album => album.iprAgreement === selectedIprAgreement);
    }

    setFilteredData(filtered);
    setTotalPages(Math.ceil(filtered.length / pageSize));
    setFilterDivOpen(false);
  };

  const handleClearFilters = () => {
    setSelectedArtworkStatus(-1);
    setSelectedAudioStatus(-1);
    setSelectedPrintProofStatus(-1);
    setSelectedIprAgreement(-1);
    setFilteredData(albums);
    setTotalPages(Math.ceil(albums.length / pageSize));
  };
  const handleEditClick =(album) =>{
    setEditingAlbum(album)
    openAlbumPopup(album);
  }
  const columns = [
    {
      title: 'Band', dataKey: 'band', sortable: true, minwidth: 220, renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() =>
          handleBandClick(
            rowData.band,
            rowData.title,
            rowData.description,
            rowData.representative,
            rowData.albumPic,
            rowData.createdByName,
            rowData.createdByEmail
          )
        }>
          <img src={TitleIcon} alt="band" className='AlbumIcon' width={24} height={20} />
          {rowData.band}
        </div>
      )
    },
    {
      title: 'Album', dataKey: 'title', sortable: true, minwidth: 180, renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={TitleIcon} alt="title" className='AlbumIcon' width={24} height={20} />
          {rowData.title}
        </div>
      )
    },
    {
      title: 'Art Work',
      dataKey: 'artworkStatus',
      sortable: true,
      minwidth: 150,

      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {rowData.artworkStatus === 0 ? (
            <div
              onClick={rowData.iprAgreement === 0 ? null : () => handleClick(rowData.id, rowData.title, rowData.artworkStatus, rowData.audioFilesStatus, rowData.representative, 'upload')}
              style={{ display: 'flex', alignItems: 'center', cursor: rowData.iprAgreement === 0 ? 'default' : 'pointer', opacity: rowData.iprAgreement === 0 ? 0.5 : 1 }}
            >
              <img src={UploadIcon} alt="Art Work Status" className='UploadIcon' width={20} height={20} style={{ marginRight: 8 }} />
              <span>Upload</span>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {rowData.artworkStatus === 1 ? (
                <>
                  <img src={ArrowCheck} alt="Art Work Status" className='ArtWork' width={20} height={20} style={{ marginRight: 8 }} />
                  <span>Submitted</span>
                </>
              ) : rowData.artworkStatus === 2 ? (
                <>
                  <img src={ArrowCheck} alt="Art Work Status" className='ApprovedIcon' width={20} height={20} style={{ marginRight: 8 }} />
                  <span>Approved</span>
                </>
              ) : rowData.artworkStatus === 3 ? (
                <>
                  <img src={Cross} alt="Art Work Status" className='RejectedIcon' width={20} height={20} style={{ marginRight: 8 }} />
                  <span>Rejected</span>
                </>
              ) : null}
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Audio Files',
      dataKey: 'audioFilesStatus',
      sortable: true,
      minwidth: 150,
      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {rowData.audioFilesStatus === 0 ? (
            <div
              onClick={rowData.iprAgreement === 0 ? null : () => handleClick(rowData.id, rowData.title, rowData.artworkStatus, rowData.audioFilesStatus, rowData.representative, 'audio')}
              style={{ display: 'flex', alignItems: 'center', cursor: rowData.iprAgreement === 0 ? 'default' : 'pointer', opacity: rowData.iprAgreement === 0 ? 0.5 : 1 }}
            >
              <img src={UploadIcon} alt="Audio Work Status" className='UploadIcon' width={20} height={20} style={{ marginRight: 8 }} />
              <span>Upload</span>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {rowData.audioFilesStatus === 1 ? (
                <>
                  <img src={ArrowCheck} alt="Audio Work Status" className='AudioWork' width={20} height={20} style={{ marginRight: 8 }} />
                  <span>Submitted</span>
                </>
              ) : null}
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Proof',
      dataKey: 'proof',
      minwidth: 150,
      renderer: (rowData) => (
        <div className='proofContainer'>
          {rowData.artworkStatus === 2 && rowData.audioFilesStatus === 1 && userType === '2' && (!rowData.artworkProofURL || rowData.artworkProofURL.trim() === '') && (

            <button
              data-tooltip-id={`view-tooltip_${rowData.albumId}`}
              data-tooltip-content="Create Proof"
              className="custom-tooltip"
              onClick={(e) => {
                e.preventDefault();
                handleProofClick(rowData.id, rowData.title, rowData.artworkProofURL, rowData.albumArtworkProofId);
              }}
            >
              <img src={GenProof} alt="First Action" width="20" height="20" />
              <span>Create Proof</span>

            </button>
          )}

          {rowData.artworkStatus === 2 && rowData.proofStatus !== 3 && userType === '2' && rowData.audioFilesStatus === 1 && rowData.artworkProofURL && rowData.proofStatus !== '0' && (

            <button
              data-tooltip-id={`view-tooltip_${rowData.id}`}
              data-tooltip-content="In Review"
              data-place='top'
              className="custom-tooltip"
              onClick={(e) => {
                e.preventDefault();
                handleProofPage(rowData.id, rowData.title, rowData.artworkProofURL, rowData.albumArtworkProofId, rowData.proofComment, rowData.proofStatus);
              }}
            >
              <img src={ViewProof} alt="First Action" width="20" height="20" />


            </button>
          )}
          {rowData.artworkStatus === 2 && userType === '1' && rowData.audioFilesStatus === 1 && rowData.artworkProofURL && rowData.proofStatus !== '0' && (

            <button
              data-tooltip-id={`view-tooltip_${rowData.id}`}
              data-tooltip-content="View Proof"
              data-place='top'
              className="custom-tooltip"
              onClick={(e) => {
                e.preventDefault();
                handleProofPage(rowData.id, rowData.title, rowData.artworkProofURL, rowData.albumArtworkProofId, rowData.proofComment, rowData.proofStatus);
              }}
              style={{ cursor: 'pointer' }}
            >
              <img src={ViewProof} alt="View Proof Icon" width="20" height="20" />
              {rowData.proofStatus === 0 && (
                <span> <u>View Proof</u></span>
              )}
              {rowData.proofStatus === 3 && (
                <span> <u>Rejected</u></span>
              )}
              {rowData.proofStatus === 2 && (
                <span> <u>Approved</u></span>
              )}
            </button>
          )}
          {rowData.proofStatus === 3 && userType === '2' && (
            <div style={{ cursor: 'pointer' }}>
              {rowData.proofComment && (
                <div style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => openCommentPopup(rowData.id, rowData.status, rowData.proofComment, rowData.albumArtworkProofId, rowData.proofHistory)}>
                  <img
                    src={CommentIcon}
                    alt="Add Comment"
                    className='CommentIcon'
                    width={18}
                    height={18}
                    style={{ width: 30 }}
                  />
                  <span style={{ cursor: 'pointer' }}> Rejected</span>
                </div>
              )}
            </div>
          )}

          {rowData.artworkStatus === 2 && rowData.proofStatus !== 2 && rowData.proofStatus !== 3 && userType === '2' && rowData.audioFilesStatus === 1 && rowData.artworkProofURL && rowData.proofStatus !== '0' && (
            <span style={{ cursor: 'pointer' }} onClick={(e) => {
              e.preventDefault();
              handleProofPage(rowData.id, rowData.title, rowData.artworkProofURL, rowData.albumArtworkProofId, rowData.proofComment, rowData.proofStatus);
            }}>In Review</span>
          )}

          {rowData.proofStatus === 2 && userType === '2' && (
            <span style={{ cursor: 'pointer' }} onClick={(e) => {
              e.preventDefault();
              handleProofPage(rowData.id, rowData.title, rowData.artworkProofURL, rowData.albumArtworkProofId, rowData.proofComment, rowData.proofStatus);
            }}> Approved</span>
          )}
        </div>
      ),
    },

    {
      title: 'IRP',
      dataKey: 'iprAgreement',
      sortable: true,
      minwidth: 150,
      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {rowData.iprAgreement === 0 ? (
            <div onClick={() => handleIRPPopup(rowData.id, rowData.title)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <img src={Waiting} alt="IRP Status" className='Waiting' width={20} height={20} style={{ marginRight: 8 }} />
              <span> Awaiting</span>
            </div>
          ) : (
            <>
              <img src={ArrowCheck} alt="IRP Status" className='ArtWork' width={20} height={20} style={{ marginRight: 8 }} />
              <span>Submitted</span>
            </>
          )}
        </div>
      )
    },
    {
      title: 'Status',
      dataKey: 'status',
      sortable: true,
      minwidth: 130,
      renderer: (rowData) => {
        let statusText = '';
        switch (rowData.status) {
          case 1:
            statusText = 'InProgress';
            break;
          case 2:
            statusText = 'InPrinting';
            break;
          case 3:
            statusText = 'Completed';
            break;
          case 4:
            statusText = 'OnHold';
            break;
          case 5:
            statusText = 'Deleted';
            break;

        }
        return (
          <div >
            {statusText}
          </div>
        );
      }
    },
    {
      title: 'Edit',
      dataKey: 'albumId',
      sortable: false,
      minwidth: 90,
      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
      <button onClick={() => handleEditClick(rowData)} className='EditIconButton'><img src={Edit} alt="View" className='EditIcon' width={24} height={20} /></button>
    </div>
      )
    },

    {
      title: 'View',
      dataKey: 'view',
      renderer: (rowData) => (
        <div>
          <div
            onClick={
              rowData.iprAgreement === 0
                ? undefined
                : () => handleClick(rowData.id, rowData.title, rowData.artworkStatus, rowData.audioFilesStatus, rowData.representative, 'artwork')
            }
            style={{
              cursor: rowData.iprAgreement === 0 ? 'default' : 'pointer',
              opacity: rowData.iprAgreement === 0 ? 0.5 : 1,
              padding: '0px 20px 0px 17px'
            }}
          >
            <img src={View} alt="View" className='ViewIcon' width={24} height={20} />
          </div>
        </div>
      ),
      minWidth: 30,
    },
    

  ];

  return (
    <>
      <Header />
      <div className="space"></div>
      <section>
        <div className="container">
          <div className="row listRow">
            <div className="col-md-6">
              <a href="#" className="add-new-album" onClick={openAlbumPopup}>Add New Album</a>
              <span className='help-icon' onClick={openHelpPopup} data-tooltip-id="my-tooltip" data-tooltip-content="Help!"></span>
              <Tooltip id="my-tooltip" />
            </div>



            <div className="col-md-6 align-right">
              <button className="filter-btn" onClick={() => setFilterDivOpen(!isFilterDivOpen)} data-tooltip-id="filter-tooltip" data-tooltip-content="Filter Data!">
                <img src={Filter} alt="Filter" width={24} height={24} />
              </button>
              <Tooltip id="filter-tooltip" />
              <div className="search">

                <form>

                  <input type="button" className="search-btn" />
                  <input type="text" onChange={(e) => handleSearch(e.target.value)} className="search-box" placeholder="Search" value={searchQuery} name="s" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isFilterDivOpen && (
        <section className="filter-table">
          <div className='container'>
            <div className="row filter-row">
              <div className="col-md-3">
                <form>
                  <select onChange={(e) => setSelectedArtworkStatus(parseInt(e.target.value))} value={selectedArtworkStatus} className='input select-filter'>
                    <option value={-1} disabled>Filter By ArtWork</option>
                    <option value={0}>Upload</option>
                    <option value={1}>Submitted</option>
                    <option value={2}>Approved</option>
                    <option value={3}>Rejected</option>
                  </select>
                </form>
              </div>
              <div className="col-md-3">
                <form>
                  <select onChange={(e) => setSelectedAudioStatus(parseInt(e.target.value))} value={selectedAudioStatus} className='input select-filter'>
                    <option value={-1} disabled>Filter By Audio FIles</option>
                    <option value={0}>Upload</option>
                    <option value={1}>Submitted</option>
                  </select>
                </form>
              </div>
              <div className="col-md-3">
                <form>
                  <select onChange={(e) => setSelectedPrintProofStatus(parseInt(e.target.value))} value={selectedPrintProofStatus} className='input select-filter'>
                    <option value={-1} disabled>Filter By Proof</option>
                    <option value={0}>Awaiting</option>
                    <option value={1}>Accepted</option>
                  </select>
                </form>
              </div>
              <div className="col-md-3">
                <form>
                  <select onChange={(e) => setSelectedIprAgreement(parseInt(e.target.value))} value={selectedIprAgreement} className='input select-filter'>
                    <option value={-1} disabled>Filter By IRP</option>
                    <option value={0}>Awaiting</option>
                    <option value={1}>Submitted</option>
                  </select>
                </form>
              </div>


              <div className="col-md-12 align-right">
                <button onClick={handleFilter} className='btn-submit'>Filter</button>
                <button onClick={handleClearFilters} className='btn-submit'>Clear</button>
              </div>

            </div>
          </div>
        </section>
      )}

      <section className="list-table">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Table
                data={filteredData}
                columns={columns}
                pageSize={pageSize}
                onSort={handleSort}
                loading={loading}
                pagination={pagination}
                onChangePage={handleChangePage}
                total={totalPages}
                activePage={currentPage}
                limit={totalPages}
                headerHeight={60}
                height={500}
                width={100}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {helpPopupOpen && <Help closeHelpPopup={closeHelpPopup} openHelpPopup={openHelpPopup} />}
      {isAlbumPopupOpen && <UploadAlbum closeAlbumPopup={closeAlbumPopup} album={editingAlbum}/>}
      {isIPRPopupOpen && (
        <IPRPopupForm
          albumValue={albumValue}
          albumTitle={title}
          closeIPRPopup={() => { setIPRPopupOpen(false); closeAlbumPopup(); }}
        />
      )}
      {isBandPopupOpen && <BandDetailsPopup
        isOpen={isBandPopupOpen}
        onClose={() => setBandPopupOpen(false)}
        bandDetails={selectedBand}
      />}


      {commentPopupOpen && (
        <AlbumComment
          isOpen={commentPopupOpen}
          closeCommentPopup={closeCommentPopup}
          albumId={selectedArtworkId}
          albumStatus={repersentiveStatus}
          albumComment={repersentiveComment}
          history={commentHistory}
        />
      )}
      {isProofPopupOpen && (
        <GenerateProof
          isOpen={isProofPopupOpen}
          closePopup={closeProofPopup}
          albumId={selectedArtworkId}
          albumStatus={selectedAlbum.status}
          albumComment={selectedAlbum.comment}
        />
      )}
      <ToastContainer />
    </>
    
  );
};

export default AlbumList;