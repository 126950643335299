import React, { useState } from 'react';
import '../../assets/css/global.css';
import LoginImage from '../../assets/images/login.jpg';
import "react-popupbox/dist/react-popupbox.css";
import TermsAndConditions from '../termCondition/termCondition';
import LoginAPi from '../../apis/login/login';
import { useNavigate } from 'react-router-dom';
import ResponseToAgreement from '../../apis/responseToAgreement/responseToAgreement';
const Login = ({ closeLoginPopup }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [authError, setAuthError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isTermsPopupOpen, setIsTermsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailError('');
        setPasswordError('');
        setIsLoading(true);
        let isValid = true;
        if (email.trim() === '') {
            setEmailError('Email is required.');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Enter a valid email.');
            isValid = false;
        }
        if (password.trim() === '') {
            setPasswordError('Password is required.');
            isValid = false;
        }

        if (!isValid) {
            setIsLoading(false);
            return;
        }
        try {
            const response = await LoginAPi(email, password);
            if (response.result === 1 && response.resultValue) {
                const { authenticationToken, userName, userType } = response.resultValue;
                sessionStorage.setItem('token', authenticationToken);
                sessionStorage.setItem('username', userName);
                sessionStorage.setItem('userType',userType);
                if (response.resultValue.cmcAgreementAccept === false) {
                    
                    setIsTermsPopupOpen(true);
                    //navigate('/albums');
                }
                else {
                    setIsTermsPopupOpen(false);
                    navigate('/albums'); 
                    
                }
            } else {
                if (response.errorValue) {
                    if (response.errorValue.errorCode === 101) {
                        setAuthError(response.errorValue.errorText);
                    }
                    else if (response.errorValue.errorCode === 104) {
                        setAuthError(response.errorValue.errorText);
                    }
                    else if (response.errorValue.errorCode === 112) {
                        setAuthError(response.errorValue.errorText);
                    }
                }
            }
        } catch (error) {
            console.error('API call error:', error);

        } finally {
            setIsLoading(false);
        }
    };

    const handleAgree = async () => {
        try {
            const authenticationToken = sessionStorage.getItem('token');
            const response = await ResponseToAgreement(authenticationToken);

            if (response.result === 1 && response.errorValue === null && response.resultValue === true) {
                setIsTermsPopupOpen(false);
                closeLoginPopup();
                navigate('/albums');
            } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 103) {
                console.error('Invalid authentication token:', response.errorValue.errorText);
                sessionStorage.clear();
                navigate('/');
            } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 112) {
                console.error('User account is not verified yet:', response.errorValue.errorText);
                sessionStorage.clear();
                navigate('/');
            } else {
                console.error('API call returned an error:', response);
            }
        } catch (error) {
            console.error('API call error:', error);

        }
    };


    const handleCloseTermsPopup = () => {
        setIsTermsPopupOpen(false);
        closeLoginPopup();
        sessionStorage.clear();
        navigate('/');
    };

    return (
        <>
            {isLoading && (
                <div className="loader-container">
                    <div className="loader"></div>

                </div>
            )}
            <div className="popup-fixed"></div>
            <div className="popup-container popup-container-fixed">
                <div className="login-popup">
                    {!isTermsPopupOpen && (
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 m-auto shadow">
                                    <div className="row relative">
                                        <div className="col-md-12">
                                            <span className="close-popup" onClick={closeLoginPopup}></span>
                                        </div>
                                        <div className="col-md-6 relative">
                                            <div className="login-space">
                                                <h2>Welcome</h2>
                                                <p>Login into your account</p>
                                                <form onSubmit={handleSubmit}>
                                                    {authError && <span className="error">{authError}</span>}
                                                    <div className="margin-bottom">
                                                        <span>Email <i className="red">*</i></span>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                        {emailError && <span className="error">{emailError}</span>}
                                                    </div>
                                                    <div className="margin-bottom">
                                                        <span>Password <i className="red">*</i></span>
                                                        <input
                                                            type="password"
                                                            className="input"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        {passwordError && <span className="error">{passwordError}</span>}
                                                    </div>
                                                    <div>
                                                        <input type="submit" className="btn-submit" value="SUBMIT" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <img src={LoginImage} alt="login" width="500" height="500" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {isTermsPopupOpen && (
                        <TermsAndConditions handleAgree={handleAgree} closeTermsPopup={handleCloseTermsPopup} />
                    )}
                </div>
            </div>
        </>
    );
};

export default Login;
