import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const RequireId = ({ children }) => {
  const { id } = useParams();

  return id ? children : <Navigate to="/" replace />;
};

export default RequireId;
