import React, { useState, useEffect, useRef } from 'react';
import LoginImage from '../../assets/images/login.jpg';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css";
import '../../assets/css/global.css';
const Help = ({ closeHelpPopup }) => {



    return (
        <>
            <div className="popup-fixed"></div>
            <div className="popup-container" data-aos="fade-down" data-aos-easing="linear">
                <div className="login-popup">
                <div className="container help" data-aos="fade-down" data-aos-easing="linear">
                    <div className="row">
                        <div className="col-md-8 m-auto shadow">
                            <div className="row relative">
                                <div className="col-md-12">
                                    <span className="close-popup" onClick={closeHelpPopup}></span>
                                </div>
                                <div className="col-md-12">
                                    <div className="terms-space help-space">
                                        <div>
                                            <p>Welcome to the world of Collectible Music Cards and thank you for joing us. On this site you will electronically sign our agreement, represent your ownership of the artworks, check the status of your order, as well as upload your music and art for your cards.</p>
                                            <p>Our agreements on this page do NOT give Community Musician ANY rights to your music or cover art, beyond your requirement the we deliver your music to the fans who have purchased cards from you directly. To be clear we are selling YOU the cards, YOU are selling your music to your fans using the cards you purchased.</p>
                                            <p>If you have any questions that are not answered on this page our in the agreements, please don't hesitate to contact us at <a href="mailto:support@collectiblemusiccards.com">support@collectiblemusiccards.com</a> and again thank you for joining us and allowing us to serve you.</p>
                                        </div>

                                        <div className="terms-footer">




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
      
    </>
  );
};

export default Help;