import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import UpdateArtworkProofStatus from '../../apis/updateArtworkProofStatus/updateArtworkProofStatus';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import Cross from '../../assets/images/icon-cross.svg';
import Check from '../../assets/images/icon-checkmark.svg';

// Set the workerSrc
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;


const ProofList = () => {
  const navigate = useNavigate();
  const { albumId } = useParams();
  const location = useLocation();
  const { albumDetails, title, artworkProofURL, albumArtworkProofId, proofComment,proofStatus } = location.state || {};
  const [isLoading, setLoading] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('approved');
  const [comment, setComment] = useState(proofComment || ''); 
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
const pdfUrl = `${proxyUrl}${artworkProofURL}`;
const storedUserType = sessionStorage.getItem('userType');

useEffect(() => {
  AOS.init();
  document.body.classList.remove('home-bg');

  
  if (proofStatus === 2) {
    setApprovalStatus('rejected');
  } else if (proofStatus === 1) {
    setApprovalStatus('approved');
  }
}, [proofStatus]);

  const handleApprovalChange = (event) => {
    setApprovalStatus(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      return 'pdf';
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
      return 'image';
    } else {
      return 'unknown';
    }
  };

  const FilePreview = ({ artworkProofURL }) => {
    const [pdfPages, setPdfPages] = useState([]);
    const fileType = getFileType(artworkProofURL);

   
      useEffect(() => {
        if (fileType === 'pdf') {
          console.log(artworkProofURL);
          const loadingTask = pdfjsLib.getDocument(artworkProofURL);
          console.log(loadingTask);
          loadingTask.promise.then(pdf => {
            const promises = [];
            for (let i = 1; i <= pdf.numPages; i++) {
              promises.push(pdf.getPage(i));
            }
            Promise.all(promises).then(pages => {
              const pageImages = pages.map(page => {
                const scale = 1.5;
                const viewport = page.getViewport({ scale });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                  canvasContext: context,
                  viewport: viewport
                };
                return page.render(renderContext).promise.then(() => {
                  return canvas.toDataURL('image/png');
                });
              });
              Promise.all(pageImages).then(images => {
                setPdfPages(images);
              });
            });
          });
        }
      }, [fileType, artworkProofURL]);
      

    if (fileType === 'image') {
      return <img src={artworkProofURL} alt="Proof Preview" style={{ maxWidth: '100%' }} />;
    } else if (fileType === 'pdf') {
      return (
        <div>
          {pdfPages.map((image, index) => (
            <img key={index} src={image} alt={`Page ${index + 1}`} style={{ maxWidth: '100%' }} />
          ))}
        </div>
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = artworkProofURL;
    link.download = `proof.${artworkProofURL.split('.').pop().toLowerCase()}`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const authenticationToken = sessionStorage.getItem('token');
    const statusValue = approvalStatus === 'approved' ? 2 : 3;
    try {
      await UpdateArtworkProofStatus(authenticationToken, albumArtworkProofId, statusValue, comment);
    } catch (error) {
      console.error('Error saving comment:', error);
    } finally {
      setLoading(false);
      setComment('');
      navigate(`/albums`);
    }
  };
 
  return (
    <>
      <Header />
      <div className="space"></div>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <section className="list-table">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="alink">
                <Link to={`/albums/`}>Albums</Link>
                <span style={{ paddingLeft: '0.7rem' }} className="albumNameLink">
                  {title}
                </span>
              </div>
            </div>
            
              <div className="col-md-6">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div style={{ textAlign: 'left', paddingTop: '15px' }}>
                          <h2>REVIEWING YOUR ONLINE PROOF</h2>
                        </div>
                        <div style={{ border: '3px solid #336699', backgroundColor: '#f1f1f1', padding: '0px 0px 5px 5px', margin: '10px 0px' }}>
                          <span style={{ color: 'red', fontSize: '16px', lineHeight: '30px' }}>
                            <strong>For an actual size printable PDF proof <button onClick={downloadFile} style={{ background: 'none', border: 'none', color: '#336699', cursor: 'pointer' }}>
                              Click Here
                            </button></strong>
                          </span>
                          <p>Acrobat PDF reader required (Download Acrobat PDF reader <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">here</a>)</p>
                          <strong><em>Please be sure to have your printer settings at "Scaling: None"</em></strong>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td height="55" valign="top">
                        <span style={{ fontSize: '16px', lineHeight: '22px' }}>
                          <strong>
                            This is your last chance to review your artwork before it enters production. It is important to take your time and review it very carefully. Please approve each checkpoint outlined below.
                          </strong>
                        </span>
                        <br />&nbsp;<br />
                        <strong style={{ fontSize: '14px', lineHeight: '22px' }}>PROOF NOTATIONS:</strong><br />
                        <p style={{ fontSize: '14px', lineHeight: '22px' }}>Magnetic stripes are represented in pink and signature panels are represented in yellow on your proof only. They will not print in those colors.<br /><br />
                        The pink outline represents the size and shape of the die line. It will not be printed.
                        Variable data is typically represented in magenta type. It will print black, unless otherwise noted on your proof’s art board.</p>
                        <strong style={{ fontSize: '14px', lineHeight: '22px' }}>PLEASE REVIEW AND CHECK OFF:</strong><br />
                        <input type="checkbox" name="checkbox1" id="checkbox1" /> <strong style={{ fontSize: '14px', lineHeight: '22px' }}> I have proofread the text, and approve the grammar, spelling and punctuation as shown on the online proof.</strong><br />
                        <input type="checkbox" name="checkbox2" id="checkbox2" /> <strong style={{ fontSize: '14px', lineHeight: '22px' }}>I have carefully inspected the graphic elements. There are no graphic elements missing. The graphic elements are positioned and oriented properly.</strong><br />
                        <input type="checkbox" name="checkbox3" id="checkbox3" /> <strong style={{ fontSize: '14px', lineHeight: '22px' }}>I have checked that all variable data shown is correct (for example, the starting and ending number, the type of bar code and the check digit). Also print this out and scan with your bar code scanner.</strong><br />
                        <input type="checkbox" name="checkbox4" id="checkbox4" /> <strong style={{ fontSize: '14px', lineHeight: '22px' }}>I understand that the colors represented on my computer screen might not match the actual printed product.</strong><br />&nbsp;<br />
                        <strong style={{ fontSize: '14px', lineHeight: '22px' }}>OTHER ELEMENTS TO CHECK FOR:</strong><br />
                        <ul>
                          <li><strong style={{ fontSize: '14px', lineHeight: '22px' }}>Is the phone number, street address, and zip code correct?</strong></li>
                          <li><strong style={{ fontSize: '14px', lineHeight: '22px' }}>Is front and back orientation correct?</strong></li>
                          <li><strong style={{ fontSize: '14px', lineHeight: '22px' }}>Is the expiration date on your card accurate?</strong></li>
                          <li><strong style={{ fontSize: '14px', lineHeight: '22px' }}>Is the correct hanging or key tag hole represented? Is it in the correct location?</strong></li>
                          <li><strong style={{ fontSize: '14px', lineHeight: '22px' }}>Check the final proof against your last set of proofs to ensure the final corrections were made.</strong><br /></li>
                        </ul>
                        
                        <span style={{ color: 'red', padding:"10px 0px",display:"block" }}>
                          <strong style={{ fontSize: '14px', lineHeight: '22px' }}>CUSTOMER RESPONSIBILITY NOTICE:</strong><br />
                          <p style={{ fontSize: '14px', lineHeight: '22px' }}>An approval of this proof establishes full responsibility for the order. Should subsequent issues be found with the order that were approved by the customer on this proof, any reprints will be the customer’s financial responsibility. <strong>We will not accept any liability for errors after the order is printed.</strong></p>
                        </span>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
                {storedUserType != 2 && (
                <form onSubmit={handleSubmit}>
                <table className='proofClass'>
                  <tbody>
                    <tr>
                      <td><img src={Cross} alt="Cross" /></td>
                      <td>
                        <input
                          type="radio"
                          id="approved"
                          name="approvalStatus"
                          value="approved"
                          checked={approvalStatus === 'approved'}
                          onChange={handleApprovalChange}
                          disabled={proofStatus !== 0}
                        />
                        <label htmlFor="approved">Approved</label>
                      </td>
                    </tr>
                    <tr>
                      <td><img src={Check} alt="Check" /></td>
                      <td>
                        <input
                          type="radio"
                          id="rejected"
                          name="approvalStatus"
                          value="rejected"
                          checked={approvalStatus === 'rejected'}
                          onChange={handleApprovalChange}
                          disabled={proofStatus !== 0}
                        />
                        <label htmlFor="rejected">Rejected</label>
                      </td>
                    </tr>
                    {approvalStatus === 'rejected' && (
                      <tr>
                        <td colSpan={2}>
                          <div>
                            <textarea
                              value={comment}
                              onChange={handleCommentChange}
                              placeholder="Enter rejection comment..."
                              rows="4"
                              cols="50"
                              disabled={proofStatus !== 0}
                            ></textarea>
                          </div>
                        </td>
                      </tr>
                    )}
                    {proofStatus === 0 && (
                      <tr>
                        <td colSpan={2}><input type="submit" className="btn-submit" value="SUBMIT" /></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </form>
              
                )}
                </div>
                <div className="col-md-6">
                <FilePreview artworkProofURL={artworkProofURL} />
                
              </div>
            </div>
          
        </div>
      </section>
      <Footer /> 
    </>
  );
};

export default ProofList;
