import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Header from '../header/header';
import AudioList from './audioList';
import UploadAudio from '../../components/uploadAudio/uploadAudio';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/global.css';
import { useAlbum } from '../../components/privateRoute/albumContext';
import Footer from '../footer/footer';
import SubmitAlbumTrack from '../../apis/submitAlbumTrack/submitAlbumTrack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Tab = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { albumDetails } = useAlbum();
  const { audioStatus} = useAlbum();
  const title = sessionStorage.getItem('title');
  const [isAudioPopupOpen, setAudioPopupOpen] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [showEditButton, setShowEditButton] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [trackCount, setTrackCount] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const openAudioPopup = () => {
    setAudioPopupOpen(true);
  };
  const status = sessionStorage.getItem('audioStatus');
    
  const closeAudioPopup = () => {
    setAudioPopupOpen(false);
    setRefreshPage(!refreshPage);
    window.location.reload();
  };

  useEffect(() => {
    AOS.init();
    document.body.classList.remove('home-bg');
  }, []);

  useEffect(() => {
    if (audioStatus === 0 ||  parseInt(status, 10) === 0) {
      setShowUploadButton(true);
      setShowSubmitButton(true);
      setShowEditButton(true);
    } else {
      setShowUploadButton(false);
      setShowSubmitButton(false);
      setShowEditButton(false);
    }
  }, [audioStatus]);

  const [activeTab, setActiveTab] = useState('audio');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleArtClick = () => {
    navigate('/artwork/' + id);
  };

  const openHandleSubmit = async () => {
    try {
      const authenticationToken = sessionStorage.getItem('token');
      const response = await SubmitAlbumTrack(authenticationToken, id);
      if (response.result === 1 && response.resultValue === true) {
        setShowSubmitButton(false);
        setShowUploadButton(false);
        setShowEditButton(false);
        toast.success('Album track Submitted successfully!');
        setTimeout(() => {
          navigate('/albums');
        }, 2000);
        
      }
    } catch (error) {
      console.error('Error submitting album track:', error);
    }
  };
 
  return (
    <>
      <Header />
      <div className="space "></div>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="alink">
                <Link to={`/albums/`}>Albums</Link>
                <span style={{ paddingLeft: '0.7rem' }} className="albumNameLink">
                  {title}
                </span>
               
              </div>
              <ul className="tab-view">
                <li className={activeTab === 'artwork' ? 'second ' : 'second'}>
                  <a href="#" onClick={() => handleArtClick('artwork')}>
                    Artwork
                  </a>
                </li>
                <li className={activeTab === 'audio' ? 'first active' : 'first'}>
                  <a href="#" onClick={() => handleTabClick('audio')}>Audio Files</a>
                </li>
                <li className="last">
                  {showUploadButton && (
                    <a href="#" className="add-new-album" onClick={openAudioPopup}>
                      Upload a new audio
                    </a>
                  )}
                  {showSubmitButton && trackCount > 0 && (
                    <a href="#" className="add-new-album add-new-album2" onClick={openHandleSubmit}>
                      Submit
                    </a>
                  )}
                </li>
              </ul>

              {activeTab === 'audio' && (
                <div className="tab-view">
                <AudioList setTrackCount={setTrackCount} showEditButton={showEditButton}/>
                </div>
              )}

             

            </div>
          </div>
        </div>
      </section>
      
      <Footer/>
      {isAudioPopupOpen && <UploadAudio closeAudioPopup={closeAudioPopup}  albumId={id}/>}
      <ToastContainer />
    </>
  );
};

export default Tab;
