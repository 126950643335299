import React, { useEffect, useState } from 'react';
import Album1 from '../../assets/images/album1.png'
import Album2 from '../../assets/images/album2.png'
import Album3 from '../../assets/images/album3.png'
import Album4 from '../../assets/images/album4.png'
import Album5 from '../../assets/images/album5.png'
import Album6 from '../../assets/images/album6.png'
import IconSpeaker from '../../assets/images/icon-speaker.svg'
import IconCard from '../../assets/images/icon-card.svg'
import IconShare from '../../assets/images/icon-share.svg'
import IconMusic from '../../assets/images/icon-music.svg'
import IconStore from '../../assets/images/icon-store.svg'
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../../assets/css/global.css'
import Header from '../header/header';
import Footer from '../footer/footer';
import Login from '../login/login';

const CopyWrite = () => {

    useEffect(() => {
        AOS.init();
    }, []);
    const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);

    const openLoginPopup = () => {
        setLoginPopupOpen(true);
    };

    const closeLoginPopup = () => {
        setLoginPopupOpen(false);
    };

    return (
        <>
            <Header openLoginPopup={openLoginPopup} />
            <section className="block-copyright" data-aos="fade-in" data-aos-duration="2000">
                <div className="relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <h1 data-aos="fade-up" data-aos-duration="1000">Copyrights and Licensing
                                    </h1>

                                    <p data-aos="fade-up" data-aos-duration="2000">Community Musician respects and will protect your intellectual property (music and artworks) to the best of our ability. We
                                        participate in the Content Delivery & Storage Association (CDSA) Anti-Piracy Compliance Program, which protects copyright owners from unauthorized reproduction of their property. We actively help protect your property rights and those of the owners
                                        of any third-party material you use on your project. While intellectual property rights procedures can sometimes be time-consuming,
                                        they are a necessary safeguard to protect the rights of everyone involved in creating digital media. Every legitimate manufacturer
                                        will require you to prove ownership of all copyrighted material before any material can be replicated.
                                    </p>
                                    <h2>Copyrighting</h2>
                                    <p data-aos="fade-up" data-aos-duration="2000">Protect your material by reregistering all original work - songs, films, scripts, artwork, software, etc. - with the United States
                                        Copyright Office. <a href="https://www.copyright.gov/" target='_blank'>(http://www.copyright.gov)</a> You automatically own the rights to your creation, but registering it with the
                                        Copyright Office is the best way to prove and protect your ownership. Songwriters may also want to affiliate and license your songs
                                        with a performing rights society, like ASCAP, BMI, or SESAC to collect performance royalties for your copyrighted songs.

                                    </p>
                                    <h2>Licensing</h2>
                                    <p data-aos="fade-up" data-aos-duration="2000">If your project contains any material owned by a third-party, we cannot begin replication unless you send in a copy of the proper
                                        proof of licensing. For most material it requires paying a small fee online and is remarkably easy. Please contact us at <a href="mailto:>licensing@collectiblemusiccards.com">licensing@collectiblemusiccards.com</a> if you need assistance.

                                    </p>
                                    <h2>Copyrights and Licensing
                                    </h2>
                                    <p data-aos="fade-up" data-aos-duration="2000">If your master contains any form of sampling you are required to purchase Master Use Licensing and include proof-of-purchase
with your project. Sampling a portion of another artist's work, regardless of the length, from TV shows, movies, commercial
sound bites, or music and video clips of any kind, requires Licensing. The owner will generally request information about the
number of units you are creating and the countries where you intend to distribute, and will charge you a modest fee.</p>
<p data-aos="fade-up" data-aos-duration="2000">If you are a musician who has recorded a cover version of someone else's song, you need to get a mechanical license authorizing
you to reproduce their composition (don't worry it's easy in most cases). You will have to get a separate license for each song .</p>
<p data-aos="fade-up" data-aos-duration="2000">We recommend Easy Song <a href="www.easysong.com" target='_blank'>(www.easysong.com)</a>, a leading license clearing firm, which offers an easy way to pay for mechanical
licenses all in one easy on-line spot.</p>
<p data-aos="fade-up" data-aos-duration="2000">Please remember for us to produce your order we need you sign our agreement and we need a completed Intellectual Property
Rights (IPR) form. Please also remember that third-party material should be credited on your artwork.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
      {isLoginPopupOpen && <Login closeLoginPopup={closeLoginPopup} />}

        </>
    );
}

export default CopyWrite;