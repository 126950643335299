import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import '../../assets/css/global.css';
import CreateArtworkProof from '../../apis/createArtworkProof/CreateArtworkProof';

const GenerateProof = ({ isOpen, closePopup, albumId}) => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); 
  const storedUserType = sessionStorage.getItem('userType');
  const [imageError, setImageError] = useState('');
   

  if (!isOpen) return null;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
     if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png' || fileType === 'application/pdf') {
        setSelectedFile(file);
        setImageError('');
      } else {
        setImageError('Unsupported file format. Please select an image (JPEG, JPG, PNG) or a PDF file.');
         e.target.value = null;
        setSelectedFile(null);
      }
    }
  };
  
  const handleFileUpload = async () => {
    if (selectedFile) {
      setLoading(true);
      console.log('Uploading file:', selectedFile);
  
      const formData = new FormData();
      formData.append('AlbumId', albumId); 
      formData.append('ProofImage', selectedFile);
  
      try {
        const authenticationToken = sessionStorage.getItem('token');
        const response = await CreateArtworkProof(authenticationToken, formData);
  
        if (response.result === 1 && response.errorValue === null) {
          closePopup(); 
        } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 103) {
          console.error('Invalid authentication token:', response.errorValue.errorText);
          sessionStorage.clear();
          
        } else {
          console.error('API call returned an error:', response);
        }
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please select a file to upload.');
    }
  };
  return (
    <>
      <div className="popup-fixed"></div>
      <div className="popup-container" data-aos="fade-down" data-aos-easing="linear">
        <div className="login-popup">
          <div className="container help" data-aos="fade-down" data-aos-easing="linear">
            <div className="row">
              <div className="col-md-6 m-auto shadow">
                <div className="row relative">
                  <div className="col-md-12">
                    <span className="close-popup" onClick={closePopup}></span>
                  </div>
                  <div className="col-md-12">
                    <div className="terms-space help-space">
                      <div>
                        <div className="comment-popup">
                          <table className="table">
                            <tbody>
                             
                              {/* File upload section */}
                              {storedUserType !== '1' && (
                                <tr>
                                  <td><b>Upload Proof:</b></td>
                                  <td>
                                    <input type="file" onChange={handleFileChange} />
                                    {imageError && <span className="error">{imageError}</span>}
                                  </td>
                                </tr>
                              )}
                               {storedUserType === '1' ? null : (
                                <tr>
                                  <td></td>
                                  <td>
                                    <button onClick={handleFileUpload} className='comment-btn'>
                                      {loading ? 'Uploading...' : 'Upload'}
                                    </button>
                                    <button onClick={closePopup} className='comment-btn'>Cancel</button>
                                    
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="terms-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateProof;
