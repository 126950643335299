import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, 
});

const LoginAPi = async (email, password) => {
    try {
        const response = await instance.post('login', {
            AccountType: 1,
            Password: password,
            UserName: email
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
       
        return response.data;
    } catch (error) {
        console.error('Error saving form data:', error);
        throw error;
    }
};

export default LoginAPi;
