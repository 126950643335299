import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const GetRepersentativeList = async (authenticationToken) => {
    try {
        const headers = {
            'Authorization': authenticationToken,
            'Content-Type': 'application/json',
        };

        const response = await instance.get('GetRepresentativeList', { headers });

        return response.data;
    } catch (error) {
        console.error('Error getting representative list:', error);
        throw error;
    }
};

export default GetRepersentativeList;
