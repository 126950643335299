import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import '../../assets/css/global.css';
import CreateArtworkProof from '../../apis/createArtworkProof/CreateArtworkProof';

const AlbumComment = ({ isOpen, closeCommentPopup, albumId, albumStatus, albumComment, history }) => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageError, setImageError] = useState('');
  const [showFileInput, setShowFileInput] = useState(false); // State for showing file input
  const storedUserType = sessionStorage.getItem('userType');

  useEffect(() => {
    if (albumStatus) {
      setStatus(mapStatusText(albumStatus)); // Set status text based on albumStatus
    }
  }, [albumStatus]);

  // Function to map status code to status text
  const mapStatusText = (statusCode) => {
    switch (statusCode) {
      case 0:
        return 'Pending';
      case 1:
        return 'In Printing';
      case 2:
        return 'Completed';
      default:
        return 'Unknown';
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png' || fileType === 'application/pdf') {
        setSelectedFile(file);
        setImageError('');
        // Do not hide file input here, let it remain visible until canceled explicitly
      } else {
        setImageError('Unsupported file format. Please select an image (JPEG, JPG, PNG) or a PDF file.');
        e.target.value = null;
        setSelectedFile(null);
      }
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      setLoading(true);
      console.log('Uploading file:', selectedFile);

      const formData = new FormData();
      formData.append('AlbumId', albumId);
      formData.append('ProofImage', selectedFile);

      try {
        const authenticationToken = sessionStorage.getItem('token');
        const response = await CreateArtworkProof(authenticationToken, formData);

        if (response.result === 1 && response.errorValue === null) {
          closeCommentPopup();
        } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 103) {
          console.error('Invalid authentication token:', response.errorValue.errorText);
          sessionStorage.clear();
        } else {
          console.error('API call returned an error:', response);
        }
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setLoading(false);
        // Regardless of success or failure, reset file input state
        setSelectedFile(null);
        
      }
    } else {
      alert('Please select a file to upload.');
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="popup-fixed"></div>
      <div className="popup-container" data-aos="fade-down" data-aos-easing="linear">
        <div className="login-popup">
          <div className="container help" data-aos="fade-down" data-aos-easing="linear">
            <div className="row">
              <div className="col-md-6 m-auto shadow">
                <div className="row relative">
                  <div className="col-md-12">
                    <span className="close-popup" onClick={closeCommentPopup}></span>
                  </div>
                  <div className="col-md-12">
                    <div className="terms-space help-space">
                      <div>
                        <div className="comment-popup">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Status</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Display History */}
                              {history && history.length > 0 && (
                                <>
                                  {history.map((item, index) => {
  const timestamp = item.modifiedDate;
  const modifiedDate = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

  // Formatting the date to display only date (no time)
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const formattedDate = modifiedDate.toLocaleDateString(undefined, options);

  let statusText = 'Unknown';
  if (item.status === 2) {
    statusText = 'Approved';
  } else if (item.status === 3) {
    statusText = 'Rejected';
  }

  return (
    <tr key={index} style={{ backgroundColor: '#c7c7c7' }}>
      <td style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>{statusText}</td>
      <td>{formattedDate}</td>
      <td>{item.representativeComment}</td>
    </tr>
  );
})}
          </>
                              )}

                              
                                <>
                                  <tr>
                                    <td colSpan="2">
                                      <b>Upload Proof:</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <input type="file" onChange={handleFileChange} />
                                      {imageError && <span className="error">{imageError}</span>}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2" align='center'>
                                      <button onClick={handleFileUpload} className='comment-btn'>
                                        {loading ? 'Uploading...' : 'Upload'}
                                      </button>
                                      <button onClick={closeCommentPopup} className='comment-btn'>Cancel</button>
                                    </td>
                                  </tr>
                                </>
                              

                              {/* Button to Show File Input */}
                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="terms-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlbumComment;
