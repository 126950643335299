import React, { useState, useEffect } from 'react';

import Table from '../../components/table/table';
import 'aos/dist/aos.css';
import AOS from 'aos';
import JSZip from 'jszip';
import '../../assets/css/global.css';
import UploadAlbum from '../../components/uploadAlbum/uploadAlbum'
import ArtWork from '../../assets/images/icon-download-black.svg'
import View from '../../assets/images/icon-view.svg'
import { useNavigate, useParams } from 'react-router-dom';
import GetAlbumArtworkList from '../../apis/getAlbumArtworkList/getAlbumArtworkList';
import Comment from './comment';
import CommentIcon from '../../assets/images/comment.svg'
import StatusChange from '../../assets/images/status-change-icon.svg'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
const ArtWorkList = ({ artworkStatus, setTrackCount }) => {
  const { id } = useParams();
  const [albums, setAlbums] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [albumsPerPage] = useState(2);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAlbums, setFilteredAlbums] = useState([]);
  const [sortState, setSortState] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [pageSize, setPageSize] = useState(3);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isAlbumPopupOpen, setAlbumPopupOpen] = useState(false);
  const [commentPopupOpen, setCommentPopupOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [comments, setComments] = useState({});
  const [selectedAlbumId, setSelectedAlbumId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [selectedArtworkId, setSelectedArtworkId] = useState(null);
  const [repersentiveComment, setRepersentiveComment] = useState('');
  const [repersentiveStatus, setRepersentiveStatus] = useState('');

  const openAlbumPopup = () => {
    setAlbumPopupOpen(true);
  };

  const closeAlbumPopup = () => {
    setAlbumPopupOpen(false);
    navigate('/audio');
  };




  const openCommentPopup = (artworkId, status, comment) => {
    setSelectedArtworkId(artworkId);
    setRepersentiveComment(comment);
    setRepersentiveStatus(status);
    setCommentPopupOpen(true);
  };

  const closeCommentPopup = () => {
    setCommentPopupOpen(false);
    setCurrentComment('');
  };

  const handleCommentSave = () => {
    setComments((prevComments) => ({
      ...prevComments,
      [selectedAlbumId]: currentComment,
    }));
    closeCommentPopup();
    fetchAlbums();
  };


  useEffect(() => {
    AOS.init();
    document.body.classList.remove('home-bg');
    const storedUserType = sessionStorage.getItem('userType');
    setUserType(storedUserType);
    fetchAlbums();
  }, [pageSize]);

  const fetchAlbums = async () => {
    setLoading(true);
    const authenticationToken = sessionStorage.getItem('token');
    try {
      const response = await GetAlbumArtworkList(authenticationToken, id);
      const result = response.resultValue;
      setTrackCount(result.length);
      const parseXml = (xmlString) => {
        if (!xmlString) return [];
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
        const items = xmlDoc.getElementsByTagName('AlbumArtworkAttachmentViewModel');
        const parsedList = [];
        for (let item of items) {
          parsedList.push({
            id: item.getElementsByTagName('Id')[0].textContent,
            artworkId: item.getElementsByTagName('ArtworkId')[0].textContent,
            mediaUrl: item.getElementsByTagName('MediaURL')[0].textContent,
            artworkContentType: item.getElementsByTagName('ArtworkContentType')[0].textContent,
            createdDate: item.getElementsByTagName('CreatedDate')[0].textContent,
            modifiedDate: item.getElementsByTagName('ModifiedDate')[0].textContent,
          });
        }

        return parsedList;
      };

      for (let album of result) {
        if (album.artworkAttachmentList) {
          try {
            const parsedList = parseXml(album.artworkAttachmentList);
            album.artworkAttachmentList = parsedList;
          } catch (error) {
            console.error('Error parsing XML:', error);
          }
        }
      }

      result.sort((a, b) => b.id - a.id);

      setAlbums(result);
      setFilteredData(result);
      const pages = Math.ceil(result.length / pageSize);
      setTotalPages(pages);


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    navigate(`/albums`);
  }


  function handleSort(column) {
    const sortedData = [...filteredData];
    const currentSort = sortState[column] || "asc";
    sortedData.sort((a, b) => {
      if (currentSort === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    const newSortState = {
      ...sortState,
      [column]: currentSort === "asc" ? "desc" : "asc"
    };
    setFilteredData(sortedData);
    setSortState(newSortState);
  }

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = albums.filter((item) => (
      item.title && item.title.toLowerCase().includes(query.toLowerCase())
    ));
    const pages = Math.ceil(filteredData.length / pageSize);
    setTotalPages(pages);
    setFilteredData(filteredData);
  };
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const downloadArtworkZip = async (artworkList) => {
    setLoading(true);
    const zip = new JSZip();


    for (let i = 0; i < artworkList.length; i++) {
      const mediaUrl = artworkList[i].mediaUrl;
      console.log(`Processing: ${mediaUrl}`);

      const encodedUrl = encodeURI(mediaUrl);

      try {
        const response = await fetch(encodedUrl);

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const blob = await response.blob();
        const fileName = mediaUrl.split("/").pop();

        // Check for invalid characters in fileName
        const sanitizedFileName = fileName.replace(/[^a-z0-9_.-]/gi, '_');

        zip.file(sanitizedFileName, blob);
        console.log(`Added file: ${sanitizedFileName}`);
      } catch (error) {
        if (error.message.includes('Failed to fetch')) {
          console.warn(`Skipping file due to fetch error: ${mediaUrl}`);
        } else {
          console.error(`Failed to fetch ${mediaUrl}:`, error);
        }
      }
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = 'artwork.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log('ZIP file download initiated');
      setLoading(false);
    }).catch(error => {
      console.error('Failed to generate ZIP file:', error);
    });
  };

  const handleStatusChange = (albumId, newStatus) => {
    setFilteredData((prevData) =>
      prevData.map((album) =>
        album.id === albumId ? { ...album, status: newStatus } : album
      )
    );

    if (newStatus === '2') {
      openCommentPopup();
    }
  };
  const columns = [

    {
      title: 'Repersentative',
      dataKey: 'representative',
      sortable: true,
      minwidth: 180,
      renderer: (rowData) => (
        <div>
          {rowData.representative}
        </div>
      ),

    },
    {
      title: 'Contact Details',
      dataKey: 'Contact_Details',
      sortable: true,
      minwidth: 350,
      renderer: (rowData) => (
        <div>
          {`${rowData.firstName} ${rowData.lastName}, ${rowData.email}, ${rowData.phone}`}
        </div>
      ),
    },
    {
      title: 'Shipping Address',
      dataKey: 'Shipping_Address',
      sortable: true,
      minwidth: 350,
      renderer: (rowData) => (
        <div>
          {`${rowData.streetAddress}, ${rowData.city}, ${rowData.state},  ${rowData.country}, ${rowData.zip}`}
        </div>
      ),
    },
    {
      title: 'Artwork',
      dataKey: 'Artwork',
      sortable: true,
      minwidth: 130,
      renderer: (rowData) => (
        <div style={{ display: 'flex', flexDirection: 'column' , padding: '9px 32px 0px 39px'}}>
          {rowData.artworkAttachmentList?.length > 0 && (
            <img src={ArtWork} alt="Art Work" className='AudioIcon' width={24} height={20} onClick={() => downloadArtworkZip(rowData.albumArtworkAttachments)} />
          )}

        </div>
      ),
    },
    {
      title: 'Status',
      dataKey: 'status',
      sortable: true,
      minwidth: 150,
      renderer: (rowData) => (
        <div className='commentDev'>

          {userType === '2' && rowData.status !== 3 && rowData.status !== 2 && (
            <button
              data-tooltip-id={`view-tooltip_${rowData.id}`}
              data-tooltip-content="Change ArtWork Status"
              data-place='top'
              className="custom-tooltip"
              onClick={() => openCommentPopup(rowData.id, rowData.status, rowData.representativeComment)}
            >
              <img src={StatusChange} alt="First Action" width="20" height="20" />
              <Tooltip id={`view-tooltip_${rowData.id}`} place="top" className="custom-tooltip" />
            </button>
          )}
          
          {userType === '1'  && rowData.status === 2 && (
            <div className="status-action-container">
              
              <span>Approved</span>
            </div>
          )}
           {userType === '2'  && rowData.status === 2 && (
            <div className="status-action-container">
              
              <span>Approved</span>
            </div>
          )}
          {userType === '1' && rowData.status == 3 && (
             <div className="status-action-container">
             <img
               src={CommentIcon}
               alt="Add Comment"
               className="CommentIcon"
               width={17}
               height={17}
               onClick={() => openCommentPopup(rowData.id, rowData.status, rowData.representativeComment)}
             />


             <span> Rejected</span>
           </div>
          )}
          {userType === '2' && rowData.status == 3 && (
             <div className="status-action-container">
             <img
               src={CommentIcon}
               alt="Add Comment"
               className="CommentIcon"
               width={17}
               height={17}
               onClick={() => openCommentPopup(rowData.id, rowData.status, rowData.representativeComment)}
             />
            <span> Rejected</span>
           </div>
           
          )}
          {userType === '1' && rowData.status !== 3 && rowData.status !== 2 && rowData.status !== 0 && (
            <span>Submitted</span>
          )}
        </div>
      ),
    },


  ];



  return (
    <>

      <div className="list-table">
        <Table
          data={filteredData}
          columns={columns}
          pageSize={pageSize}
          onSort={handleSort}
          loading={loading}
          pagination={pagination}
          onChangePage={handleChangePage}
          total={totalPages}
          activePage={currentPage}
          limit={totalPages}
          headerHeight={60}
          height={450}
          width={100}
        />
      </div>

      {isAlbumPopupOpen && <UploadAlbum closeAlbumPopup={closeAlbumPopup} />}

      {commentPopupOpen && (
        <Comment
          isOpen={commentPopupOpen}
          closeCommentPopup={closeCommentPopup}
          currentComment={currentComment}
          setCurrentComment={setCurrentComment}
          handleCommentSave={handleCommentSave}
          artworkId={selectedArtworkId}
          artworkStatus={repersentiveStatus}
          artworkComment={repersentiveComment}
          onSave={handleCommentSave}
        />
      )}
    </>
  );
};

export default ArtWorkList;
