import React from 'react';

const BandDetailsPopup = ({ isOpen, onClose, bandDetails }) => {
  if (!isOpen || !bandDetails) return null;

  const isPDF = bandDetails.albumPic && bandDetails.albumPic.endsWith('.pdf');

  return (
    <>
      <div className="popup-fixed"></div>
      <div className="popup-container" data-aos="fade-down" data-aos-easing="linear">
        <div className="login-popup">
          <div className="container help" data-aos="fade-down" data-aos-easing="linear">
            <div className="row">
              <div className="col-md-8 m-auto shadow">
                <div className="row relative">
                  <div className="col-md-12">
                    <span className="close-popup" onClick={onClose}></span>
                  </div>
                  <div className="col-md-12">
                    <div className="terms-space help-space">
                      <div>
                        <h2 style={{ padding: "0px 0px 1rem 0px" }}>{bandDetails.band}</h2>

                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td><b>Title:</b></td>
                              <td>{bandDetails.title}</td>
                            </tr>
                            <tr>
                              <td><b>Description:</b></td>
                              <td>{bandDetails.description}</td>
                            </tr>
                            <tr>
                              <td><b>Representative:</b></td>
                              <td>{bandDetails.representative}</td>
                            </tr>
                            <tr>
                              <td><b>Created by:</b></td>
                              <td>{bandDetails.createdByName}</td>
                            </tr>
                            <tr>
                              <td><b>Email:</b></td>
                              <td>{bandDetails.createdByEmail}</td>
                            </tr>
                            <tr>
                              <td><b>Cover Image:</b></td>
                              <td>
                                {isPDF ? (
                                  <a
                                    href={bandDetails.albumPic}
                                    download="Album.pdf"
                                    className="download-link"
                                    target='_blank'
                                    rel="noopener noreferrer"
                                  >
                                    Download PDF
                                  </a>
                                ) : (
                                  <>
                                    <img
                                      src={bandDetails.albumPic}
                                      alt="Album"
                                      width={200}
                                      height={200}
                                      className='brandImage'
                                    />
                                    <div></div>
                                    <div>
                                      <a
                                        href={bandDetails.albumPic}
                                        download="AlbumImage.jpg"
                                        className="download-link"
                                        target='_blank'
                                        rel="noopener noreferrer"
                                      >
                                        Download Image
                                      </a>
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="terms-footer">
                        {/* Additional footer content */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BandDetailsPopup;
