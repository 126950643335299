import React, { useState, useEffect } from 'react';
import '../../assets/css/global.css';
import createAlbumTrack from '../../apis/createAlbumTrack/createAlbumTrack';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UploadAudio = ({ audio, closeAudioPopup, albumId }) => {
    const [albumName, setAlbumName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedAudios, setSelectedAudios] = useState([]);
    const [imageError, setImageError] = useState('');
    const [audioDurations, setAudioDurations] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [coverImageName, setCoverImageName] = useState('');
    const [albumNameError, setAlbumNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [audioError, setAudioError] = useState('');

    useEffect(() => {
        if (audio) {
            setAlbumName(audio.title || '');
            setDescription(audio.description || '');
            

            if (audio.coverPic) {
                const imageName = getFileNameFromUrl(audio.coverPic);
                setCoverImageName(imageName);
                    fetchImageAsFile(audio.coverPic)
                    .then(file => {
                     // setImage(file);
                      //setImageName(file.name); 
                    })
                      .catch(error => console.error('Error converting image:', error));
                  
            }
           
            if (audio.audioURL) {
                fetch(audio.audioURL)
                .then(response => response.blob())
                .then(blob => {
                    // Create a File object from the Blob
                    const file = new File([blob], 'audio.mp3', { type: blob.type });
                    //setSelectedAudios([file]);
                })
                .catch(error => {
                    console.error('Error fetching audio file:', error);
                    setAudioError('Failed to fetch audio file.');
                });


                const urls = Array.isArray(audio.audioURL) ? audio.audioURL : [audio.audioURL];
                const files = urls.map(url => ({
                    name: getFileNameFromUrl(url), 
                    url
                }));
                
               

                const seenFileNames = new Set();

                files.forEach(file => {
                    const cleanFileName = file.name.replace(/^\d+_/, '');

                    if (!seenFileNames.has(cleanFileName)) {
                        seenFileNames.add(cleanFileName);

                        const audioElement = new Audio(file.url);

                        audioElement.onloadedmetadata = () => {
                            const duration = audioElement.duration;

                            setAudioDurations(prevDurations => {
                                if (prevDurations.find(ad => ad.name === cleanFileName)) {
                                    return prevDurations;
                                }
                                return [
                                    ...prevDurations,
                                    { name: cleanFileName, duration }
                                ];
                            });
                        };
                    }
                });
            }

           
        }
    }, [audio]);
    const fetchImageAsFile = async (imageUrl) => {
      try {
          const response =  await fetch(imageUrl, {
            mode: "no-cors"
        })
       
          if (!response.ok) throw new Error('Failed to fetch image');
          const blob = await response.blob();
          const file = new File([blob], 'cover-image.jpg', { type: blob.type });
          return file;
        } catch (error) {
          console.log('Error fetching image as file:', error);
          return null;
        }
      };

    
      const getFileNameFromUrl = (url) => {
        const urlObject = new URL(url);
        const pathname = urlObject.pathname;
        const filename = pathname.split('/').pop();
        const decodedFilename = decodeURIComponent(filename);
        return decodedFilename.replace(/^\d+_/, '');
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        
        if (file) {
            const fileType = file.type;
            let error = '';

            if (
                !fileType.includes('jpeg') &&
                !fileType.includes('jpg') &&
                !fileType.includes('png') &&
                !fileType.includes('avif') &&
                !fileType.includes('svg')
            ) {
                error = 'Image must be JPEG, JPG, PNG, AVIF, or SVG';
            }

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > 10) {
                error = 'Image size must be less than 10MB';
            }
            
            setSelectedImage(file);
            setCoverImageName(file.name);
            setImageError(error);
        }
    };

    const handleAudioChange = (event) => {
        const files = Array.from(event.target.files);
        
        setSelectedAudios(prevSelectedAudios => [...prevSelectedAudios, ...files]);
        const newDurations = [];
        files.forEach(file => {
            const audioElement = document.createElement('audio');
            audioElement.src = URL.createObjectURL(file);
            audioElement.addEventListener('loadedmetadata', () => {
                const duration = audioElement.duration;
                const cleanFileName = file.name.replace(/^\d+_/, ''); 
                newDurations.push({ name: cleanFileName, duration });
                if (newDurations.length === files.length) {
                    setAudioDurations(newDurations);
                }
    
                displayAudioDuration(cleanFileName, duration); 
            });
        });
    
        if (files.length > 0) {
            setAudioError('');
        }
    };
    

    const displayAudioDuration = (filename, duration) => {
        const durationElement = document.createElement('span');
        durationElement.className = 'audio-duration';
        durationElement.innerText = `${filename} - Duration: ${duration.toFixed(2)} seconds`;
         
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let newAlbumNameError = '';
        let newDescriptionError = '';
        let newImageError = '';
        let newAudioError = '';

        if (!albumName.trim()) {
            newAlbumNameError = 'Album name is required';
        }
        if (!description.trim()) {
            newDescriptionError = 'Description is required';
        }
        if (!audio && !selectedImage) {
            newImageError = 'Image is required';
        }
        if (!audio && selectedAudios.length === 0) {
            newAudioError = 'At least one audio file is required';
        }

        setAlbumNameError(newAlbumNameError);
        setDescriptionError(newDescriptionError);
        setImageError(newImageError);
        setAudioError(newAudioError);
        if(!audio){
          if (newAlbumNameError || newDescriptionError || newImageError || newAudioError) {
            return;
          }
        }
        else{
            if (newAlbumNameError || newDescriptionError ) {
                return;
              } 
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('Title', albumName);
        formData.append('Description', description);
       let totalDurationInSeconds = selectedAudios.reduce((total, audio) => {
            let duration = audioDurations.find(ad => ad.name === audio.name)?.duration || 0;
            return total + duration;
        }, 0);

        let hours = Math.floor(totalDurationInSeconds / 3600);
        let minutes = Math.floor((totalDurationInSeconds % 3600) / 60);
        let seconds = Math.floor(totalDurationInSeconds % 60);
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        formData.append('MediaLength', formattedDuration);
         
       

        if (audio?.id) { 
            formData.append('AlbumId', albumId);
            formData.append('Id', audio.id); 
            if(selectedImage){
                formData.append('AlbumImage', selectedImage);
                formData.append('IsPicChanged', true);
            }
            else{
                formData.append('AlbumImage', '');
                formData.append('IsPicChanged', false);
            }
            if(selectedAudios.length > 0){
                selectedAudios.forEach(audio => {
                    formData.append('AlbumTrack', audio);
                });
                formData.append('IsTrackChange', true); 
            }
            else{
                formData.append('AlbumTrack', '');
                formData.append('IsTrackChange', false); 
            }
            
        } else {
            formData.append('AlbumId', albumId); 
            if(selectedImage){
                formData.append('AlbumImage', selectedImage);
                formData.append('IsPicChanged', true);
            }
            if(selectedAudios.length > 0){
                selectedAudios.forEach(audio => {
                    formData.append('AlbumTrack', audio);
                });
                formData.append('IsTrackChange', true); 
            }
        }
        try {
            const authenticationToken = sessionStorage.getItem('token');
            const response = await createAlbumTrack(authenticationToken, formData);
            if (response.result === 1 && response.errorValue === null) {
                if(!audio){
                toast.success('Album track created successfully!');
                }
                else{
                toast.success('Album track updated successfully!');
                }
            } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 103) {
                console.error('Invalid authentication token:', response.errorValue.errorText);
                sessionStorage.clear();
                navigate('/');
            } else {
                console.error('API call returned an error:', response);
            }
        } catch (error) {
            console.error('API call error:', error);
        } finally {
            setLoading(false);
            setTimeout(() => {
                closeAudioPopup();
              }, 2000);
            
        }
        
    };

    return (
        <>
            <div className="popup-fixed"></div>
            <div className="popup-container popup-container-fixed" data-aos="fade-down" data-aos-easing="linear">
                <div className="login-popup">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 m-auto shadow">
                                <div className="row relative">
                                    <div className="col-md-12">
                                        <span className="close-popup" onClick={closeAudioPopup}></span>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="album-add">
                                            <form className="form-overflow" onSubmit={handleSubmit}>
                                                <div className="margin-bottom">
                                                    {coverImageName ? (
                                                        <label htmlFor="file-upload" className="custom-file-upload">
                                                            <i className=""></i> {coverImageName}
                                                        </label>
                                                    ) : (
                                                        <label htmlFor="file-upload" className="custom-file-upload">
                                                            <i className=""></i> Upload cover image
                                                        </label>
                                                    )}

                                                    <input id="file-upload" type="file" accept="image/*" onChange={handleImageChange} disabled={isLoading} />
                                                    {imageError && <div className="error">{imageError}</div>}
                                                </div>

                                                <div className="margin-bottom">
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        placeholder="Name of the track"
                                                        value={albumName}
                                                        onChange={(e) => {
                                                            setAlbumName(e.target.value);
                                                            setAlbumNameError('');
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                    {albumNameError && <div className="error">{albumNameError}</div>}
                                                </div>
                                                <div className="margin-bottom">
                                                    <textarea
                                                        className="input"
                                                        placeholder="Description"
                                                        cols=""
                                                        rows=""
                                                        value={description}
                                                        onChange={(e) => {
                                                            setDescription(e.target.value);
                                                            setDescriptionError('');
                                                        }}
                                                        disabled={isLoading}
                                                    ></textarea>
                                                    {descriptionError && <div className="error">{descriptionError}</div>}
                                                </div>

                                                <div id="audio-container" className="margin-bottom">
                                                    <label htmlFor="add-audio" className="custom-file-upload custom-file-upload2">
                                                        Upload Track-1
                                                    </label>
                                                    <input id="add-audio" type="file" accept="audio/*" multiple onChange={handleAudioChange} style={{ display: 'none' }} disabled={isLoading} />
                                                </div>
                                                {audioError && <div className="error">{audioError}</div>}

                                                <div>
                                                    {audioDurations.map((audio, index) => (
                                                        <p key={index} className='fileDetail'>
                                                            {audio.name} - Duration: {audio.duration.toFixed(2)} seconds
                                                        </p>
                                                    ))}
                                                </div>

                                                <div className="align-center">
                                                <input type="submit" className={`btn-submit ${isLoading ? 'disabled' : ''}`} value={audio ? 'UPDATE' : 'SUBMIT'} disabled={isLoading} />
                                                    {isLoading && <span className="albumloader">  Loading...</span>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default UploadAudio;
