import React, { useState, useEffect } from 'react';
import Table from '../../components/table/table';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../../assets/css/global.css';
import GetAlbumTrackList from '../../apis/getAlbumTrackList/getAlbumTrackList';
import Header from '../header/header';
import UploadAudio from '../../components/uploadAudio/uploadAudio';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/global.css';
import { useAlbum } from '../../components/privateRoute/albumContext';
import Footer from '../footer/footer';
import SubmitAlbumTrack from '../../apis/submitAlbumTrack/submitAlbumTrack';
import Edit from '../../assets/images/edit.svg'
const AudioList = ({ setTrackCount, showEditButton}) => {
  console.log(showEditButton);
  const [audios, setAudios] = useState([]);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredAudios, setfilteredAudios] = useState([]);
  const [sortState, setSortState] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const { albumDetails } = useAlbum();
  const { audioStatus} = useAlbum();
  const title = sessionStorage.getItem('title');
  const [isAudioPopupOpen, setAudioPopupOpen] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [editingAudio, setEditingAudio] = useState(null);

  
  const openAudioPopup = () => {
    setAudioPopupOpen(true);
  };

  const closeAudioPopup = () => {
    setAudioPopupOpen(false);
  };
  
  useEffect(() => {
    AOS.init();
    document.body.classList.remove('home-bg');
    if (audioStatus === 0) {
      setShowUploadButton(true);
      setShowSubmitButton(true);
    } else {
      setShowUploadButton(false);
      setShowSubmitButton(false);
    }
    fetchAlbums();
  }, [pageSize,audioStatus]);

  const fetchAlbums = async () => {
    setLoading(true);
    const authenticationToken = sessionStorage.getItem('token');
    try {
      const response = await GetAlbumTrackList(authenticationToken, id);
      const result = response.resultValue;
      result.sort((a, b) => b.id - a.id);

      setAudios(result);
      setFilteredData(result);
      const pages = Math.ceil(result.length / pageSize);
      setTotalPages(pages);
      setTrackCount(result.length);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const [activeTab, setActiveTab] = useState('audio');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleArtClick = () => {
    navigate('/artwork/' + id);
  };

  const openHandleSubmit = async () => {
    try {
      const authenticationToken = sessionStorage.getItem('token');
      const response = await SubmitAlbumTrack(authenticationToken, id);
      if (response.result === 1 && response.resultValue === true) {
        setShowSubmitButton(false);
        setShowUploadButton(false);
      }
    } catch (error) {
      console.error('Error submitting album track:', error);
    }
  };

  function handleSort(column) {
    const sortedData = [...filteredData];
    const currentSort = sortState[column] || "asc";
    sortedData.sort((a, b) => {
      if (currentSort === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    const newSortState = {
      ...sortState,
      [column]: currentSort === "asc" ? "desc" : "asc"
    };
    setFilteredData(sortedData);
    setSortState(newSortState);
  }

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = audios.filter((item) => (
      item.title && item.title.toLowerCase().includes(query.toLowerCase())
    ));
    const pages = Math.ceil(filteredData.length / pageSize);
    setTotalPages(pages);
    setFilteredData(filteredData);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  
  const handleEditClick = (audio) => {
    setEditingAudio(audio);
    openAudioPopup();
  };

  
  const columns = [
    
    
    {
      title: 'Title',
      dataKey: 'title',
      sortable: true,
      minwidth: 550,
      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center', padding: '7px 0px 0px 0px' }}>
          <img src={rowData.coverPic} width={50} height={50} style={{ marginLeft: "20px" }}  alt="Cover"/>
          <span style={{ paddingLeft: "20px" }}>{rowData.title}</span>
        </div>
      )
    },
    {
      title: 'Audio Files',
      dataKey: 'audioURL',
      sortable: false, 
      minwidth:400,
      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' , padding: '2px 20px 0px 37px'}}>
          <audio controls>
            <source src={rowData.audioURL} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )
    },,
    {
      title: 'Format',
      dataKey: 'format',
      sortable: true,
      minwidth: 150,
      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' , padding: '0px 33px 0px 44px'}}>
          mp3
        </div>
      )
    },
    {
      title: 'Edit',
      dataKey: 'albumId',
      sortable: false,
      minwidth: 150,
      renderer: (rowData) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
      <button onClick={() => handleEditClick(rowData)} className='EditAudioButton' disabled={!showEditButton}><img src={Edit} alt="View" className='EditIcon' width={24} height={20} /></button>
    </div>
      )
    },
  ];

  return (
    <>
    
    <section className="list-table">
    <div className="aduio-list">
      {/*<div className="description-bg">
        <div className="img-left">
          <img src={Image} alt="" width="125" height="125" />
        </div>
        <div className="content-right">
          <h3>Community Musician, Inc. - CM000021: The Particles - Inhumanity [795397197678]</h3>
          <p>
            Title track inspired by the horror of school shootings in association with "Peaceful Streets"
            which with the help of police collects community guns and melts them into park benches
          </p>
        </div>
      </div>*/}
   
      <div>
        <Table
          data={filteredData}
          columns={columns}
          pageSize={pageSize}
          onSort={handleSort}
          loading={loading}
          pagination={pagination}
          onChangePage={handleChangePage}
          total={totalPages}
          activePage={currentPage}
          limit={totalPages}
          headerHeight={60}
          height={480}
          width={100}
          showRowNumber={true}
        />
      </div>
    </div>
  </section>
  
  {isAudioPopupOpen && (
        <UploadAudio
              audio={editingAudio}
              closeAudioPopup={() => setAudioPopupOpen(false)}
              albumId={id}
              onSubmit={openHandleSubmit}
            />
  )}
  </>
  );
};

export default AudioList;
