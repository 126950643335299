import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, 
});

const ResponseToAgreement = async (authenticationToken) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authenticationToken,
    };
    const body = {
      responseToAgreement: true,
    };
    const response = await instance.post('ResponseToAgreement', body, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    console.error('Error sending response to agreement:', error);
    throw error;
  }
};

export default ResponseToAgreement;
