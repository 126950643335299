import React, { useState, useEffect } from 'react';
import "react-popupbox/dist/react-popupbox.css";
import '../../assets/css/global.css';
import IPRPopupForm from '../../main/iprForm/iprForm';
import CreateAlbum from '../../apis/createAlbum/createAlbum';
import { useNavigate } from 'react-router-dom';
import GetRepersentativeList from '../../apis/getRepersentativeList/getRepersentativeList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UploadAlbum = ({ closeAlbumPopup, album }) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [band, setBand] = useState(''); 
  const [titleError, setTitleError] = useState('');
  const [imageError, setImageError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [bandError, setBandError] = useState(''); 
  const [isIPRPopupOpen, setIPRPopupOpen] = useState(false);
  const [imageName, setImageName] = useState('');
  const [albumValue, setalbumValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [representatives, setRepresentatives] = useState([]);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const [representativeError, setRepresentativeError] = useState('');
  const [representativeName, setRepresentativeName] = useState('');
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    if (!e.target.value.trim()) {
      setTitleError('The Title field is required.');
    } else {
      setTitleError('');
    }
  };
  
  const handleImageChange = (e) => {

    const file = e.target.files[0];
    setImage(file);
    setImageName(file.name);
    if (!file) {
      setImageError('The Album Cover field is required.');
    } else {
      setImageError('');
    }
    // Check image type
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/avif', 'application/pdf'];
    if (!allowedTypes.includes(file.type)) {
      setImage(null);
      setImageError('Only PNG, JPG, JPEG, SVG, AVIF, and PDF formats are allowed');
      return;
    } else {
      setImageError('');
    }
    const maxSizeInBytes = 10 * 1024 * 1024;
    if (file.size >= maxSizeInBytes) {
      setImage(null);
      setImageError('The Album Cover size exceeds 10MB');
      return;
    } else {
      setImageError('');
    }

    setImage(file);
    setImageName(file.name);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (!e.target.value.trim()) {
      setDescriptionError('The Description field is required.');
    } else {
      setDescriptionError('');
    }
  };

  const handleBandChange = (e) => {
    setBand(e.target.value);
    if (!e.target.value.trim()) {
      setBandError('The Band Name is required.');
    } else {
      setBandError('');
    }
  };

  const handleRepresentativeChange = (e) => {
    setSelectedRepresentative(e.target.value);
    setRepresentativeError('');
  };

  useEffect(() => {

    if (album) {
      setTitle(album.title || '');
      setDescription(album.description || '');
      setBand(album.band || '');
      setRepresentativeName(album.representative || '');

      if (album.albumPic) {
        fetchImageAsFile(album.albumPic)
        .then(file => {
          //setImage(file);
          setImageName(file.name); 
        })
          .catch(error => console.error('Error converting image:', error));
      }
    }


    const authenticationToken = sessionStorage.getItem('token');

    const fetchRepresentatives = async () => {
      try {
        const response = await GetRepersentativeList(authenticationToken);

        if (response.result === 1) {
          setRepresentatives(response.resultValue);
        } else {
          console.error('Failed to fetch representatives:', response.errorValue);
        }
      } catch (error) {
        console.error('Error fetching representatives:', error);
      }
    };

    fetchRepresentatives();
  }, [album]);

  useEffect(() => {
    if (representatives.length > 0 && representativeName) {
      const representative = representatives.find(rep => rep.userName === representativeName);

      if (representative) {
        setSelectedRepresentative(representative.userId);

      } else {
        console.log('Representative not found');
      }
    }
  }, [representatives, representativeName]);

  const fetchImageAsFile = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) throw new Error('Failed to fetch image');
      
      const blob = await response.blob();
  
      const urlParts = imageUrl.split('/');
      let filename = urlParts[urlParts.length - 1].split('?')[0]; 
  
      filename = filename.replace(/\d+_*/g, ''); 
      filename = filename.replace(/^[^\w]+|[^\w]+$/g, ''); 
      filename = filename || 'image.jpg';
  
      const file = new File([blob], filename, { type: blob.type });
      return file;
    } catch (error) {
      console.error('Error fetching image as file:', error);
      return null;
    }
  };
  
  
  
  const getSubmitButtonValue = () => {
    if (album.title) {
      return 'Update';
    }
    return 'Submit';
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      setTitleError('The Title field is required.');
    }
    if (!album.title && !image) {
      setImageError('The Album Cover field is required.');
    }
    if (!description.trim()) {
      setDescriptionError('The Description field is required.');
    }
    if (!band.trim()) {
      setBandError('The Band name is required.');
    }
    if (!selectedRepresentative) {
      setRepresentativeError('Please select a representative.');
    }

    if (title.trim() && description.trim() && band.trim() && selectedRepresentative) {
      setLoading(true);
      const formData = new FormData();
      
      formData.append('Title', title);
      formData.append('Description', description);
      formData.append('Band', band);
      formData.append('RepresentativeId', selectedRepresentative);
      if (album.title) { 
        formData.append('AlbumId', album.id);
        if (image) { 
          formData.append('IsPicChanged', true);
          formData.append('AlbumCover', image);
        } else {
          formData.append('IsPicChanged', false);
          //formData.append('AlbumCover', ''); 
        }
      } else { 
        formData.append('AlbumId', "0");
        if (image) { 
          formData.append('IsPicChanged', true);
          formData.append('AlbumCover', image);
        }
      }

      try {
        const authenticationToken = sessionStorage.getItem('token');
        const response = await CreateAlbum(authenticationToken, formData);
        if (response.result === 1 && response.errorValue === null) {
          
          setalbumValue(response.resultValue);
          if (album.title) {
            toast.success('Album successfully updated!');
            setTimeout(() => {
              closeAlbumPopup();
              setIPRPopupOpen(false);
            }, 1000);
          } else {
            toast.success('Album successfully created!');
            setTimeout(() => {
              setIPRPopupOpen(true);
            }, 2000);
            
          }
          setTitle(title);
          setImage(null);
          setImageName('');
          setDescription('');
          setBand('');
        } else if (response.result === 0 && response.errorValue && response.errorValue.errorCode === 103) {
          console.error('Invalid authentication token:', response.errorValue.errorText);
          sessionStorage.clear();
          navigate('/');
        } else {
          console.error('API call returned an error:', response);
        }
      } catch (error) {
        console.error('API call error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="popup-fixed"></div>
      <div className="popup-container popup-container-fixed" data-aos="fade-down" data-aos-easing="linear">
        <div className="login-popup">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto shadow">
                <div className="row relative">
                  <div className="col-md-12"><span className="close-popup" onClick={closeAlbumPopup}></span></div>
                  <div className="col-md-12">
                    <div className="album-add">
                      <form onSubmit={handleSubmit}>
                        <div className='margin-bottom'>
                          <select
                            className="input"
                            value={selectedRepresentative}
                            onChange={handleRepresentativeChange}
                            name="Representative"
                          >
                            <option value="" >Select Representative</option>
                            {representatives.map(rep => (
                              <option key={rep.userId} value={rep.userId}>
                                {rep.userName}
                              </option>
                            ))}
                          </select>
                          {representativeError && <span className="error">{representativeError}</span>}
                        </div>
                        <div className="margin-bottom">
                          <input type="text" className="input" placeholder="Band Name" value={band} onChange={handleBandChange} disabled={isLoading} />
                          {bandError && <span className="error">{bandError}</span>}
                        </div>
                        <div className="margin-bottom">
                          <input type="text" className="input" placeholder="Name of the album" value={title} onChange={handleTitleChange} disabled={isLoading} />
                          {titleError && <span className="error">{titleError}</span>}
                        </div>
                        <div className="margin-bottom">
                          <label htmlFor="file-upload" className="custom-file-upload"><i className=""></i> Upload cover image</label><input id="file-upload" type="file" name="" onChange={handleImageChange} disabled={isLoading} />
                          {imageError && <span className="error">{imageError}</span>}
                        </div>
                        {imageName && <p className='albumCover'>Uploaded Cover Image: {imageName}</p>}

                        <div className="margin-bottom">
                          <textarea className="input" placeholder="Description" name="Description" value={description} onChange={handleDescriptionChange} disabled={isLoading}></textarea>
                          {descriptionError && <span className="error">{descriptionError}</span>}
                        </div>
                        <div className="align-center">
                          <input
                            type="submit"
                            className={`btn-submit ${isLoading ? 'disabled' : ''}`}
                            value={getSubmitButtonValue()}
                            disabled={isLoading}
                          />
                          {isLoading && <span className="albumloader">  Loading...</span>}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isIPRPopupOpen && (
        <IPRPopupForm albumValue={albumValue} albumTitle={title} closeIPRPopup={() => { setIPRPopupOpen(false); closeAlbumPopup(); }} />
      )}
       <ToastContainer />
    </>
    
  );
};

export default UploadAlbum;
