import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, 
});

const UpdateArtworkProofStatus = async (authenticationToken,artworkId,status, comment) => {
  try {
    const headers = {
      'Authorization': authenticationToken,
      'Content-Type': 'application/json',
    };
    const body = {
        "albumArtworkProofId": artworkId,
        "Status": status,
        "RepresentativeComment": comment
      };

    const response = await instance.post('UpdateArtworkProofStatus', body, { headers });
    return response.data;
  } catch (error) {
    console.error('Error sending response to agreement:', error);
    throw error;
  }
};

export default UpdateArtworkProofStatus;
